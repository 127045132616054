<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contentFormAccess">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 min-w-[90vw] md:min-w-[70vw] xl:min-w-[60vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Accesos a módulos específicos del sistema
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <form class="w-full space-y-6" @submit.prevent>
          <div class="grid md:grid-cols-2 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Sucursales
              </span>
              <Stores @send-data="getStores" :selectOption="dataForm.idStore" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Usuarios
              </span>
              <div
                v-if="refreshUser"
                class="py-2 text-sm rounded-lg border-2 px-3 bg-gray-100 text-gray-400"
              >
                Selecciona una sucursal
              </div>
              <Users v-else @send-data="getUsers" :idstore="dataForm.idStore" />
            </label>
          </div>

          <section>
            <div class="py-4 px-3 bg-white grid lg:grid-cols-2 gap-4">
              <label
                class="flex flex-col cursor-pointer"
                v-for="(area, i) in access"
                :key="i"
                @click="area.state ? deleteAccessEspecific(area.title,area.accessId,$event,showAccess) : null"
              >
                <div
                  class="relative inline-flex items-center h-6 cursor-pointer"
                >
                  <input
                    type="checkbox"
                    v-model="area.state"
                    class="sr-only peer"
                      :disabled="area.state"
                      :checked="area.state"
                    @change="addAcess(area.title, i)"
                  />
                  <div
                    class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                  ></div>
                  <span
                    class="ml-3 text-xs font-medium text-gray-500 dark:text-gray-300"
                  >
                    {{ area.title }}
                  </span>
                </div>
              </label>
            </div>
            <div class="grid md:grid-cols-2 gap-4">
              <label class="block" v-show="dataForm.accesForSale">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Descuento en el módulo
                </span>
                <input
                  type="number"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.discount"
                  placeholder="Descuento en el módulo..."
                  @keydown.enter.prevent
                />
              </label>
              <label class="block" v-show="dataForm.accesForSale">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Contraseña para aplicar descuento
                </span>
                <input
                  type="password"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                  v-model="dataForm.password"
                  placeholder="Ingresa una contraseña segura..."
                  @keydown.enter.prevent
                />
              </label>
            </div>
          </section>

          <div class="flex mt-[3.5rem!important] lg:mt-[2rem!important]">
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando acceso del usuario
              </div>
              <span v-else>Registrar acceso</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onBeforeMount, onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import axios from "@/api";
import Users from "@/components/PublicComponents/Users.vue";
import Stores from "@/components/PublicComponents/Stores.vue";

import systemEspecificAccess from "/public/apis/system-especific-acces.json";
import { idLocalStore } from "@/publicjs/localStorage";
import deleteAccessEspecific from "@/repository/users/deleteAccessEspecific";

export default {
  name: "RegisterSystemEspecificAcess",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
  },
  components: {
    Users,
    Stores,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      areas: "REALIZAR DESCUENTOS EN VENTA",
      discount: 0,
      password: null,
      accesForSale: false,
    });

    const getUsers = async (data) => {
      dataForm.value.idUser = data;
      await showAccess();
    };

    const refreshUser = ref(true); // variable para refresecar el componente de usuarios
    const getStores = async (data) => {
      refreshUser.value = true;
      dataForm.value.idStore = data;
      await getUsers();
      refreshUser.value = false;
    };

    const access = ref(systemEspecificAccess);
    access.value = access.value.map((item) => {
      return {
        ...item,
        state: false,
      };
    });
    const accessArray = ref([]);
    const addAcess = (description, i) => {
      if (description === "REALIZAR DESCUENTOS EN VENTA") {
        if (access.value[i].state === true) {
          dataForm.value.accesForSale = true;
        } else {
          dataForm.value.accesForSale = false;
        }
      }
      const exist = accessArray.value.findIndex(
        (item) => item.area === description
      );
      if (exist !== -1) {
        accessArray.value.splice(exist, 1);
      } else {
        accessArray.value.push({
          area: description,
        });
      }
    };

    const insert = async () => {
      if (dataForm.value.accesForSale===true) {
        if (dataForm.value.password ===null || dataForm.value.password.length <= 0) {
        errorActions(
          "Ingresa la contraseña y el valor de descuento que puede realizar el usuario seleccionado"
        );
        return;
      }
      }
      if (dataForm.value.idUser <= 0) {
        errorActions(
          "Selecciona un  <strong>usuario</strong> para registrar su acceso"
        );
        return;
      }
      if (accessArray.value.length <= 0) {
        errorActions(
          "Selecciona uno o mas accesos del  <strong>usuario</strong>."
        );
        return;
      }
      spinner.value = true;
      const response = await axios
        .post(
          "accesos-especificos",
          {
            idUser: dataForm.value.idUser,
            areas: accessArray.value,
            discount: dataForm.value.discount,
            password: dataForm.value.password,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `El acceso al área   <strong>${dataForm.value.areas}</strong> del usuario `
        );
        dataForm.value.discount = 0;
        dataForm.value.password = null;
        props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.msg === "exist") {
        errorActions(
          `El usuario ya tiene un  <strong>acceso configurado para esa área</strong> intenta resgitrando otro acceso`
        );
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar el acceso  <strong>${dataForm.value.areas}</strong> del usuario`
        );
      }

      props.activeModal();
      spinner.value = false;
    };

    const showAccess = async () => {
      const response = await axios
        .get("accesos-especificos-de-usuario/" + dataForm.value.idUser, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;

      access.value = access.value.map((module) => {
        const access = data.find((access) => access.areas === module.title);
        if (access) {
          module.state = true;
        } else {
          module.state = false;

        }
        return {
          ...module,
          accessId:access?access.id:null
        };
      });
    };

    onBeforeMount(async () => {
      refreshUser.value = true;
      dataForm.value.idStore = await idLocalStore();
      refreshUser.value = false;
    });
    onMounted(async () => {
      observeElement("#contentFormAccess");
    });
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      getUsers,
      getStores,
      refreshUser,
      systemEspecificAccess,
      addAcess,
      access,
      deleteAccessEspecific,
      showAccess
    };
  },
};
</script>
