<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="contenUpdateForm">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 min-w-[90vw] md:min-w-[40vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Actualizar datos de pago del servicio
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <LoadingForms v-if="!isLoading" />
        <form class="w-full space-y-6" @submit.prevent v-else>
          <div class="grid md:grid-cols-4 gap-4">
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° comprobante <span class="text-red-300">*</span>
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.numberVuocher"
                placeholder="N° comprobante..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                N° comprobante modificado
              </span>
              <input
                type="text"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.moidifiedReceiptNumber"
                placeholder="N° comprobante modificado..."
                @keydown.enter.prevent
                maxlength="30"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en servicios <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.amountInServices"
                placeholder="Monto en servicios..."
                @keydown.enter.prevent
                min="0"
                @input="calculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto en bienes <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.amountInGoods"
                placeholder="Monto en bienes..."
                @keydown.enter.prevent
                min="0"
                @input="calculateTotal"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto total facturado <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.totalAmountBilled"
                placeholder="Monto total facturado..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} facturado <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvInvoiced"
                placeholder="facturado..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} retenido <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvWithheld"
                placeholder="retenido..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} sujeto a la proporcionalidad
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvSubjectToProportionality"
                placeholder="sujeto a la proporcionalidad..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} llevado a costo
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvCarriedAtCost"
                placeholder="llevado a costo..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} por adelantar
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvForAdvance"
                placeholder="por adelantar..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                {{ config.iva }} percivido en compras
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.igvPerceivedInPurchase"
                placeholder="percivido en compras..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Tipo de retencion ISR <span class="text-red-300">*</span>
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none focus:border-blue-500 w-full"
                v-model="dataForm.withholdingTypeInISR"
              >
                <option value="Alquileres">Alquileres</option>
                <option value="Honorarios por servicios">
                  Honorarios por servicios
                </option>
                <option value="Otras rentas">Otras rentas</option>
                <option value="Otras rentas (rentas presuntas)">
                  Otras rentas (rentas presuntas)
                </option>
                <option
                  value="Intereses pagados a personas jurídicas residentes"
                >
                  Intereses pagados a personas jurídicas residentes
                </option>
                <option value="Intereses pagados a personas físicas residentes">
                  Intereses pagados a personas físicas residentes
                </option>
                <option value="Retención por proveedores del Estado">
                  Retención por proveedores del Estado
                </option>
                <option value="Juegos telefónicos">Juegos telefónicos</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto de retencion renta <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.rentWithholdingAmOunt"
                placeholder="Monto de retencion renta..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                ISR percivido en compras <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.ISRPerceivedInPurChases"
                placeholder="ISR percivido en compras..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Impuesto selectivo al consumo
                <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.exciseTax"
                placeholder="Impuesto selectivo al consumo..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Otros impuestos tasas <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.otherTaxesFees"
                placeholder="Otros impuestos tasas..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Monto propina legal <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.legalTipAmount"
                placeholder="Monto propina legal..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Forma de pago <span class="text-red-300">*</span>
              </span>
              <select
                class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none focus:border-blue-500 w-full"
                v-model="dataForm.waToPay"
              >
                <option value="01-Efectivo">01-Efectivo</option>
                <option value="02-Cheques/Transferencias/Depósitos">
                  02-Cheques/Transferencias/Depósitos
                </option>
                <option value="03-Tarjeta crédito/débito">
                  03-Tarjeta crédito/débito
                </option>
                <option value="04-Compra a crédito">04-Compra a crédito</option>
                <option value="05-Permuta">05-Permuta</option>
                <option value="06-Notas de crédito">06-Notas de crédito</option>
                <option value="07-Mixto">067-Mixto</option>
              </select>
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de pago <span class="text-red-300">*</span>
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.paymentDate"
                placeholder="Fecha de pago..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Fecha de comprobante <span class="text-red-300">*</span>
              </span>
              <input
                type="date"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                v-model="dataForm.voucherDate"
                placeholder="Fecha de comprobante..."
                @keydown.enter.prevent
                min="0"
              />
            </label>
          </div>
          <button
            class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
            @click="update"
            :disabled="spinner === true"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="@/assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Modificando Información
            </div>
            <span v-else>Modificar</span>
          </button>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions } from "@/alerts";
import axios from "@/api";

//componente de loading
import LoadingForms from "@/components/Loadings/LoadingForms.vue";
import { observeElement } from "@/observer";
import config from "/public/apis/config.json";
export default {
  name: "UpdateCustomers",
  components: {
    LoadingForms,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const dataForm = ref({
      numberVuocher: "",
      moidifiedReceiptNumber: "",
      amountInServices: 0,
      amountInGoods: 0,
      totalAmountBilled: 0,
      igvInvoiced: 0,
      igvWithheld: 0,
      igvSubjectToProportionality: 0,
      igvCarriedAtCost: 0,
      igvForAdvance: 0,
      igvPerceivedInPurchase: 0,
      withholdingTypeInISR: "Alquileres",
      rentWithholdingAmOunt: 0,
      ISRPerceivedInPurChases: 0,
      exciseTax: 0,
      otherTaxesFees: 0,
      legalTipAmount: 0,
      waToPay: "01-Efectivo",
      paymentDate: null,
      voucherDate: null,
      state: "ACTIVO",
      descriptionService: "",
    });

    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(false);
    onMounted(async () => {
      observeElement("#contenUpdateForm");
      const response = await axios
        .get("pago-de-servicios/" + props.id)
        .catch((error) => errorActions(error));
      const data = await response.data;
      dataForm.value.numberVuocher = data.numero_facturado;
      dataForm.value.moidifiedReceiptNumber =
        data.numero_comprobante_modificado;
      dataForm.value.amountInServices = parseFloat(data.monto_en_servicios);
      dataForm.value.amountInGoods = parseFloat(data.monto_en_bienes);
      dataForm.value.totalAmountBilled = parseFloat(data.total_monto_facturado);
      dataForm.value.igvInvoiced = parseFloat(data.igv_facturado);
      dataForm.value.igvWithheld = parseFloat(data.igv_retenido);
      dataForm.value.igvSubjectToProportionality = parseFloat(
        data.igv_sujeto_a_proporcionalidad
      );
      dataForm.value.igvCarriedAtCost = parseFloat(data.igv_llevado_a_costo);
      dataForm.value.igvForAdvance = parseFloat(data.igv_por_adelantar);
      dataForm.value.igvPerceivedInPurchase = parseFloat(
        data.igv_percibido_en_compras
      );
      dataForm.value.withholdingTypeInISR = data.tipo_retencion_en_isr;
      dataForm.value.rentWithholdingAmOunt = parseFloat(
        data.monto_retencion_renta
      );
      dataForm.value.ISRPerceivedInPurChases = parseFloat(
        data.isr_percivido_en_compras
      );
      dataForm.value.exciseTax = parseFloat(data.impuesto_selectivo_al_consumo);
      dataForm.value.otherTaxesFees = parseFloat(data.otros_impuestos_tasas);
      dataForm.value.legalTipAmount = parseFloat(data.monto_propina_legal);
      dataForm.value.waToPay = data.forma_de_pago;
      dataForm.value.paymentDate = data.fecha_de_pago;
      dataForm.value.voucherDate = data.fecha_de_comprobante;
      dataForm.value.state = data.estado;

      isLoading.value = true;
    });

    const update = async () => {
      if (
        dataForm.value.paymentDate === null ||
        dataForm.value.paymentDate === ""
      ) {
        errorActions(
          "Selecciona una   <strong>fecha de pago del servicio</strong>"
        );
        return;
      }
      if (
        dataForm.value.voucherDate === null ||
        dataForm.value.voucherDate === ""
      ) {
        errorActions("Selecciona una   <strong>fecha del comprobante</strong>");
        return;
      }
      if (dataForm.value.totalAmountBilled <= 0) {
        errorActions(
          "El monto total facturado debe de ser   <strong>mayor a 0</strong>"
        );
        return;
      }

      spinner.value = true;

      const response = await axios
        .put(
          "pago-de-servicios/" + props.id,
          {
            numberVuocher: dataForm.value.numberVuocher,
            moidifiedReceiptNumber: dataForm.value.moidifiedReceiptNumber,
            amountInServices: dataForm.value.amountInServices,
            amountInGoods: dataForm.value.amountInGoods,
            totalAmountBilled: dataForm.value.totalAmountBilled,
            igvInvoiced: dataForm.value.igvInvoiced,
            igvWithheld: dataForm.value.igvWithheld,
            igvSubjectToProportionality:
              dataForm.value.igvSubjectToProportionality,
            igvCarriedAtCost: dataForm.value.igvCarriedAtCost,
            igvForAdvance: dataForm.value.igvForAdvance,
            igvPerceivedInPurchase: dataForm.value.igvPerceivedInPurchase,
            withholdingTypeInISR: dataForm.value.withholdingTypeInISR,
            rentWithholdingAmOunt: dataForm.value.rentWithholdingAmOunt,
            ISRPerceivedInPurChases: dataForm.value.ISRPerceivedInPurChases,
            exciseTax: dataForm.value.exciseTax,
            otherTaxesFees: dataForm.value.otherTaxesFees,
            legalTipAmount: dataForm.value.legalTipAmount,
            waToPay: dataForm.value.waToPay,
            paymentDate: dataForm.value.paymentDate,
            voucherDate: dataForm.value.voucherDate,
            state: dataForm.value.state,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate("El pago del servicio ");
        await props.activeModal(false);
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `El servicio que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos del servicio <trong>servicio </strong>`
        );
      }

      spinner.value = false;
    };

    const calculateTotal = () => {
      dataForm.value.totalAmountBilled =
        dataForm.value.amountInGoods + dataForm.value.amountInServices;
    };

    return {
      isActive,
      dataForm,
      update,
      isLoading,
      spinner,
      calculateTotal,
      config,
    };
  },
};
</script>
