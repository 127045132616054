<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <button
        class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="activeModalRegister"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
        Nuevo registro
      </button>
      <!-- componente para las sucursales -->
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1">Sucursales</span>
        <select
          v-model="idstore"
          @change="resetValuesAndGetData"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
        >
          <option
            :value="dataStore.id"
            v-for="(dataStore, i) in dataStores"
            :key="i"
          >
            {{ dataStore.razon_social }}
          </option>
        </select>
      </label>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
      1: Para poner una tabla responsiva añadir lo siguiente:
        1.1: class="resize-generate"
        1.2: ref="widthNav" 
        1.3: @resize="handleResize"
      -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="8" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 flex items-center whitespace-nowrap"
                >
                  <div class="w-8 h-8 mr-3">
                    <div class="w-8 h-8 rounded-full overflow-hidden">
                      <img
                        src="@/assets/images/defaults/avatar-default.png"
                        :alt="register.nombres"
                        v-if="register.avatar === null"
                      />
                      <img
                        :src="APIURL + register.avatar"
                        :alt="register.nombres"
                        v-else
                      />
                    </div>
                  </div>
                  {{ register.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.tipodocumento }}
                  {{ register.numero_documento }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.tipousuario }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.direccion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.correo }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.telefonos }}
                </td>
                <td
                  class="border-slate-200 text-[12px] px-6 py-2 whitespace-nowrap"
                >
                  <div class="flex items-center" v-if="register.estado === 1">
                    <div
                      class="h-2.5 w-2.5 rounded-full bg-green-500 mr-2"
                    ></div>
                    Activo
                  </div>
                  <div class="flex items-center" v-else>
                    <div class="h-2.5 w-2.5 rounded-full bg-red-500 mr-2"></div>
                    Inactivo
                  </div>
                </td>
                <td
                  class="border-slate-200 text-sm px-2 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-green-100 text-green-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-green-500 mr-3"
                    @click="activeModalUpdatePassword(register.id)"
                  >
                    <font-awesome-icon icon="fa-solid fa-key" class="w-3 h-3" />
                  </button>
                  <button
                    class="bg-blue-100 text-blue-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                    @click="activeModalUpdate(register.id)"
                  >
                    <font-awesome-icon icon="fa-solid fa-pen" class="w-3 h-3" />
                  </button>
                  <button
                    class="bg-red-50 ml-3 text-red-500 hover:bg-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105"
                    @click="isDelete(register.nombres, register.id)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      class="w-3 h-3"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterUsers
    :activeModal="activeModalRegister"
    :getdata="getData"
    v-if="stateRegister"
  />
  <UpdateUsers
    :activeModal="activeModalUpdate"
    :getdata="getData"
    :id="id"
    v-if="stateUpdate"
  />
  <UpdateUsersPassword
    :activeModal="activeModalUpdatePassword"
    :getdata="getData"
    :id="id"
    v-if="stateUpdatePassword"
  />
</template>

<script>
import { onBeforeMount, onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterUsers from "@/components/Users/Main/RegisterUsers.vue";
import UpdateUsers from "@/components/Users/Main/UpdateUsers.vue";
import UpdateUsersPassword from "@/components/Users/Main/UpdateUsersPassword.vue";
import Swal from "sweetalert2";
import { errorActions, warningActions } from "@/alerts";
import { idLocalStore } from '@/publicjs/localStorage';
import { selectRow } from "@/publicjs/selectRowTable";
export default {
  name: "MainUsers",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterUsers,
    UpdateUsers,
    UpdateUsersPassword,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Nombres y apellidos",
        },
        {
          title: "N° de documento",
        },
        {
          title: "Tipo de usuarios",
        },
        {
          title: "Dirección",
        },
        {
          title: "Correo",
        },
        {
          title: "Telefonos",
        },
        {
          title: "Estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    //sucursales
    const idstore = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `usuarios/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idstore.value}`
        )
        .catch((error) => {
          errorActions(error);
        });

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    // funcion para cargar las sucursales
    const dataStores = ref([]);
    const getStores = async () => {
      const response = await axios.get("sucursales").catch((error) => {
        errorActions(error);
      });
      if ((await response.data.data.length) === 0) {
        errorActions("no pudimos econtrar las sucursales");
      }
      dataStores.value = await response.data.data;
      idstore.value = dataStores.value[0].id;
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        },
        // { immediate: true }
      );
    };
    onBeforeMount(async () => {
      await getStores();
      idstore.value = await idLocalStore()
      await getData();
    })

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const stateUpdate = ref(false);
    const stateUpdatePassword = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };

    const id = ref(0);
    const activeModalUpdate = (idData) => {
      stateUpdate.value = !stateUpdate.value;
      id.value = idData;
    };

    const activeModalUpdatePassword = (idData) => {
      stateUpdatePassword.value = !stateUpdatePassword.value;
      id.value = idData;
    };

    //Acciones para eliminar
    const deleteData = async (register, id) => {
      const response = await axios
        .delete("usuarios/" + id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === "Request failed with status code 500") {
        errorActions(data.msg);
      } else if (data.msg === false) {
        errorActions("Lo sentimos no pudimos inactivar al usuario " + register);
      }
    };
    //SweetAlert para confirmas la eliminacion del registro
    const isDelete = (register, id) => {
      Swal.fire({
        title: "¿Estas seguro que deseas inactivar al usuario?",
        text: `Si inactivas a (${register}), podras activarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡Inactivar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteData(register, id);
          Swal.fire({
            icon: "success",
            title: "Inactivado",
            text: `${register}, a sido inactivado en la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          await getData();
        }
      });
    };

    //URL PARA LOS RECURSO DE LA API COMO IMAGNES, ARCHIVOS, ETC

    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      activeModalUpdate,
      isDelete,
      id,
      stateUpdate,
      idstore,
      dataStores,
      widthNav,
      handleResize,
      APIURL,
      activeModalUpdatePassword,
      stateUpdatePassword,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
