<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <!-- acciones de impresion -->
      <div class="relative group">
        <button
          class="bg-blue-100 flex items-center justify-center w-28 h-9 rounded-md text-blue-600"
        >
        Opciones
          <font-awesome-icon icon="fa-solid fa-ellipsis" class="rotate-90 ml-2" />
        </button>
        <ul
          class="absolute top-full left-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
        >
          <button
            class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
            @click="exportPDF(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a PDF
          </button>
          <button
            class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
            @click="exportToExcel(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a excel
          </button>
        </ul>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por lote o producto..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full" id="contentDataTables">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="8" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap bg-blue-50 text-blue-500"
                >
                  {{ register.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.nombre_producto }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                 <span class="px-3 py-1 rounded-md text-sm" 
                 :class="{
                  'bg-orange-50 text-orange-600':calculateExpirationDate(register.fecha_vencimiento).includes('VENCE EN'),
                  'bg-orange-500 text-white':calculateExpirationDate(register.fecha_vencimiento).includes('VENCE MAÑANA'),
                  'bg-red-50 text-red-600':calculateExpirationDate(register.fecha_vencimiento).includes('VENCE HOY'),
                  'bg-red-500 text-white':calculateExpirationDate(register.fecha_vencimiento).includes('VENCIDO'),
                  'bg-green-50 text-green-600':calculateExpirationDate(register.fecha_vencimiento).includes('-') || calculateExpirationDate(register.fecha_vencimiento).includes('/')}"
                 >
                  {{ calculateExpirationDate(register.fecha_vencimiento) }}
                 </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.cantidad }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                {{ money() }} {{ format(register.precio_compra) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                {{ money() }} {{ format(register.precio_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 text-sm rounded-lg"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.estado ==='VIGENTE',
                      'bg-green-50 text-green-600':
                        register.estado ==='VENDIDO',
                        'bg-red-50 text-red-600':
                        register.estado ==='ANULADO',
                        'bg-orange-50 text-orange-600':
                        register.estado ==='DEVOLUCION'
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-green-100 text-green-600 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-green-700"
                    @click="sendMarkAsSoldInLot(register.id, register.descripcion)"
                    v-if="register.estado ==='VIGENTE'"
                  >
                  <font-awesome-icon icon="fa-solid fa-check" class="w-3 h-3 mr-1" />
                  Marcar vendido
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";

//Imports para exportar los lotes a PDF y EXCEL
import {exportToExcel} from './Reports/ExcelExportAllLots'
import {exportPDF} from './Reports/PDFExportAllLots'

//Marcar el lote como vendido
import {markAsSoldInLot} from '@/repository/Lots/MarkAsSoldInLots'

//Para calcular el vencimiento del producto
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import { selectRow } from "@/publicjs/selectRowTable";

export default {
  name: "MainCustomers",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Lote",
        },
        {
          title: "productos",
        },
        {
          title: "fec. vencimiento",
        },
        {
          title: "cantidad",
        },
        {
          title: "p. de compra",
        },
        {
          title: "p. de venta",
        },
        {
          title: "estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0)
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `lotes/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      observeElement('#contentDataTables')
      idStore.value = await idLocalStore()
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //Llamada de funcion para anular la venta esta funcion biene desde el repositorio
    const sendMarkAsSoldInLot = async (id,msg) => {
      await markAsSoldInLot(id,msg,resetValuesAndGetData)
    }


    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      exportToExcel,
      exportPDF,
      sendMarkAsSoldInLot,
      calculateExpirationDate,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
