<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="main-content-credit-note-sale"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[40vw]"
        id="subcontent-credit-note-sale"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Devolucion de venta / Nota de credito
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div>
            <h2 class="text-gray-700 font-bold text-sm">
              Detalles de la venta
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="5" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(sale, i) in arrayDataOrder"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.nombre_producto }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.cantidad }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.precio_de_venta) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-blue-50 text-blue-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="
                            addProducts({
                              id: sale.id,
                              idSale: sale.idventa,
                              idProduct: sale.idproducto,
                              taxFree: sale.libre_de_impuesto,
                              unitMeasure: sale.unidad_de_medida,
                              quantityUnitMeasure: parseFloat(
                                sale.cantidad_contenida_por_unidad
                              ),
                              discountApplied: parseInt(
                                sale.descuento_aplicado
                              ),
                              discountInsurance: parseInt(
                                sale.descuento_por_seguro
                              ),
                              igvApplied: parseInt(sale.igv_aplicado),
                              salePrice: parseFloat(sale.precio_de_venta),
                              quantity: parseFloat(sale.cantidad),
                              igv: parseFloat(sale.igv),
                              subTotal: parseFloat(sale.subtotal),
                              total: parseFloat(sale.total),
                              productName: sale.nombre_producto,
                            })
                          "
                        >
                          <font-awesome-icon icon="fa-solid fa-plus" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div class="mt-10 border-t-2 border-blue-300 pt-5">
            <h2 class="text-blue-500 font-bold text-md mb-3">
              Detalles de productos de devolver
            </h2>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <LoadingTables v-if="loading" :columns="5" />
                  <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                    <tr
                      v-for="(shopping, i) in arrayReturnProducts"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ shopping.productName }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="shopping.quantity"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="
                            handleQuantityChange(i, $event, shopping.igvApplied)
                          "
                          @keyup.enter="
                            handleQuantityChange(i, $event, shopping.igvApplied)
                          "
                        />
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.salePrice) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(shopping.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-center"
                      >
                        <button
                          class="bg-red-50 text-red-500 text-sm font-bold px-2 py-1 rounded-md"
                          @click="removeProduct(i)"
                        >
                          <font-awesome-icon icon="fa-solid fa-cancel" />
                        </button>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <CreditNoteTotalDetails
              :total="totalOfTheOrder"
              :subTotal="subTotalOfTheOrder"
              :iva="ivaOfTheOrder"
            />
            <div
              class="flex justify-between mt-3 items-end flex-wrap bg-blue-50 px-3 py-3 rounded-md border-blue-50 border-2"
            >
              <label class="block w-full md:w-auto">
                <span
                  class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
                >
                  Motivo de devolución
                </span>
                <input
                  type="email"
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
                  v-model="dataForm.motive"
                  placeholder="INGRESA EL MOTIVO DE LA DEVOLUCION..."
                  @keydown.enter.prevent
                  maxlength="95"
                />
              </label>
              <label class="block w-full md:w-auto">
                <span class="block text-gray-600 font-semibold mb-2 text-sm"
                  >Tipo de NC</span
                >
                <select
                  class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none mr-3"
                  @change="typeCreditNote"
                  v-model="dataForm.typeCreditNote"
                >
                  <option value="INTERNO">NC INTERNO</option>
                  <option value="VÁLIDO">NC VÁLIDO</option>
                </select>
              </label>
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full md:w-auto justify-center"
                @click="insert"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Registrando Información
                </div>
                <span v-else>Registrar</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observeElement } from "@/observer";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "../../../api";
import { format, money } from "@/publicjs/money";
import CreditNoteTotalDetails from "./CreditNoteTotalDetails.vue";
import { idLocalStore, idLocalUser } from "@/publicjs/localStorage";
import { selectRow } from "@/publicjs/selectRowTable";
import {
  updateNumberVoucher,
  voucherCreditNote,
  voucherCreditNoteInternal,
} from "@/publicjs/vouchers";

//Impresion de comprobantes
import { printVoucherOnA4 } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "@/components/Sales/CreditNote/PrintVoucher/PrintOnTicket";
import { percentage } from "@/publicjs/convertToPercentage";

export default {
  name: "CreditNote",
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
  },
  components: {
    LoadingTables,
    TableHead,
    CreditNoteTotalDetails,
  },
  setup(props) {
    function isActive() {
      props.activeModal(true);
    }

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información
    const loading = ref(true);

    const totalOfTheOrder = ref(0);
    const subTotalOfTheOrder = ref(0);
    const ivaOfTheOrder = ref(0);

    const dataForm = ref({
      idUser: 0,
      idStore: 0,
      idVoucher: 0,
      numberVoucher: "",
      serieVoucher: "",
      nameVoucher: "",
      motive: "",
      printFormat: "",
      typeCreditNote: "INTERNO",
    });
    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "Importe",
        },
        {
          title: "Total",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //funcion para traer los detalles de la venta
    const arrayDataOrder = ref([]);
    const arrayReturnProducts = ref([]);
    const getSaleDetails = async () => {
      const response = await axios
        .get(`detalle-de-ventas/${props.id}`)
        .catch((error) => errorActions(error));
      const data = response.data;
      if (data.length <= 0) {
        errorActions("No hemos podido encontrar los detalles de la compra");
      } else {
        arrayDataOrder.value = data;
        loading.value = false;
      }
    };

    /*****************************************************************************
     * Funcion para validar si ya se emitio una nota de credito del producto
     * @param {*} idProduct ID del producto a verificar
     * @param {*} idSale ID de la venta
     *****************************************************************************/
    const verifyProduct = async (idSale, idProduct) => {
      const response = await axios.get(`verificar-nota-de-credito-emitido/${idSale}/${idProduct}`).catch(error=>error(error))
      return await response.data.state
      
    }

    /*****************************************************************************
     * Funcion para añadir producto a lista de productos a devolver
     * @param {*} detail detalle de la venta
     *****************************************************************************/
    const addProducts = async (detail) => {
      const {
        id,
        idSale,
        idProduct,
        taxFree,
        unitMeasure,
        quantityUnitMeasure,
        discountApplied,
        discountInsurance,
        igvApplied,
        salePrice,
        quantity,
        igv,
        subTotal,
        total,
        productName,
      } = detail;

      const isVerify = await verifyProduct(idSale,idProduct)

      if(isVerify) {
        errorActions(
          "Usted ya no puede emitir la nota de credito del producto.<br> <strong>Ya se emitió una nota de crédito del producto anteriormente.</strong>"
        );
        return
      }
      // Verificar si el producto ya ha sido agregado
      const isProductAdded = arrayReturnProducts.value.some(
        (product) =>
          product.idDetail === id && product.productName === productName
      );

      if (isProductAdded) {
        errorActions(
          "El producto que esta intentando añadir ya se añadió con anterioridad a la lista de productos a devolver"
        );
        return;
      }

      arrayReturnProducts.value.push({
        id,
        idSale,
        idProduct,
        taxFree,
        unitMeasure,
        quantityUnitMeasure,
        discountApplied,
        discountInsurance,
        igvApplied,
        salePrice,
        quantity,
        igv,
        subTotal,
        total,
        productName,
      });
      calculateTheTotal(arrayReturnProducts.value);
    };

    /*****************************************************************************
     * Funcion para quitar productos de la lista de devolucion
     * @param {*} index Indice del registro que sea elminado
     *****************************************************************************/
    const removeProduct = (index) => {
      // Verificar si el índice está dentro de los límites del array
      if (index >= 0 && index < arrayReturnProducts.value.length) {
        arrayReturnProducts.value.splice(index, 1);
        calculateTheTotal(arrayReturnProducts.value);
      } else {
        errorActions("Índice inválido. No se pudo remover el producto.");
      }
    };

    /*****************************************************************************
     * Funcion para cambiar la cantidad de la orden
     * @param {*} index Indice del registro
     * @param {*} event El evento en este caso el valor que vendra en ese evento
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      if (newQuantity > arrayReturnProducts.value[index].quantity) {
        errorActions(
          "Lo sentimos, Usted no puede devolver un producto mayor a la que ingreso a almacén"
        );
        event.target.value = arrayReturnProducts.value[index].quantity;
        return;
      }
      if (newQuantity <= 0) {
        errorActions(
          "Lo sentimos, Usted no puede devolver un producto menor a 0"
        );
        event.target.value = arrayReturnProducts.value[index].quantity;
        return;
      }

      arrayReturnProducts.value[index].quantity = newQuantity;
      updateDetailData(index, ivaAplied);
    };
    /*****************************************************************************
     * Funcion para actualizar el valor en el objeto y sacar un nuevo calculo total
     * @param {*} index Indice del registro
     * @param {*} ivaAplied IVA aplicado al producto
     *****************************************************************************/
    const updateDetailData = (index, ivaAplied) => {
      const order = arrayReturnProducts.value[index];
      let totalOrder = 0;

      const priceForDiscount =
        order.salePrice * percentage(order.discountApplied);
      const newPrice = order.salePrice + priceForDiscount;

      totalOrder = order.quantity * newPrice;

      //parseamos el iva del producto
      const configureIva = percentage(ivaAplied);

      //volvemos a calcular el total de orden del producto seleccionado
      order.subTotal = parseFloat(totalOrder / (configureIva + 1)).toFixed(2);
      order.iva = parseFloat(order.subTotal * configureIva).toFixed(2);
      order.total = totalOrder.toFixed(2);

      //llamamos la funcion para calcular el total de orden y le pasamos como parametro el detalle de la orden
      calculateTheTotal(arrayReturnProducts.value);
    };

    /*****************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el iva, subtotal
     * @param {*} order el array de objetos de productos de la tabla
     *****************************************************************************/
    const calculateTheTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      totalOfTheOrder.value = 0;
      subTotalOfTheOrder.value = 0;
      ivaOfTheOrder.value = 0;

      //recorremos todo el detalle de la orden
      order.forEach((element) => {
        totalOfTheOrder.value += parseFloat(element.total);
        subTotalOfTheOrder.value += parseFloat(element.subTotal);
        ivaOfTheOrder.value += parseFloat(element.igv);
      });
    };

    onMounted(async () => {
      observeElement("#main-content-credit-note-sale");
      observeElement("#subcontent-credit-note-sale");
      await getSaleDetails();

      dataForm.value.idStore = await idLocalStore();
      dataForm.value.idUser = await idLocalUser();
    });

    const insert = async () => {
      spinner.value = true;
      if (dataForm.value.typeCreditNote === "INTERNO") {
        const { id, description, numberCurrent, printFormat, serie } =
          await voucherCreditNoteInternal();
        dataForm.value.idVoucher = id;
        dataForm.value.nameVoucher = description;
        dataForm.value.numberVoucher = numberCurrent;
        dataForm.value.serieVoucher = serie;
        dataForm.value.printFormat = printFormat;
      } else {
        const { id, description, numberCurrent, printFormat, serie } =
          await voucherCreditNote();
        dataForm.value.idVoucher = id;
        dataForm.value.nameVoucher = description;
        dataForm.value.numberVoucher = numberCurrent;
        dataForm.value.serieVoucher = serie;
        dataForm.value.printFormat = printFormat;
      }

      if (arrayReturnProducts.value.length <= 0) {
        errorActions(
          "No podemos realizar la  <strong>DEVOLUCION DE PRODUCTOS</strong> debido a que no a agregado ningun producto a  la lista de devolución"
        );
        spinner.value = false;
        return;
      }
      if (dataForm.value.motive.length <= 3) {
        errorActions(
          "Debe especificar el motivo por la cual se esta haciendo la <strong>DEVOLUCION</strong> de la compra"
        );
        spinner.value = false;
        return;
      }
      const response = await axios
        .post(
          "nota-de-credito-ventas",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idSale: props.id,
            idVoucher: dataForm.value.idVoucher,
            numberVoucher: dataForm.value.numberVoucher,
            serieVoucher: dataForm.value.serieVoucher,
            subTotal: subTotalOfTheOrder.value,
            igv: ivaOfTheOrder.value,
            total: totalOfTheOrder.value,
            motive: dataForm.value.motive,
            type: "NC",
            nameVoucher: dataForm.value.nameVoucher,
            arrayDetail: arrayReturnProducts.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `La emision de la <strong>Nota de crédito</strong> `
        );
        //? Impresion de comprobante segun su formato
        if (dataForm.value.printFormat === "formato_a4") {
          await printVoucherOnA4(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (dataForm.value.printFormat === "formato_8x11") {
          await printVoucherOnLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (dataForm.value.printFormat === "formato_media_carta") {
          await printVoucherOnMediaLetter(data.id); // Como parametro recive el ID  de la ultima orden
        } else if (dataForm.value.printFormat === "formato_ticket") {
          await printVoucherTicket(data.id); // Como parametro recive el ID  de la ultima orden
        }

        await updateNumberVoucher(
          dataForm.value.idVoucher,
          dataForm.value.numberVoucher
        );

        props.getdata();
        isActive();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar la <strong>Nota de crédito</strong>`
        );
      }

      spinner.value = false; //dejando activo al boton
    };
    return {
      isActive,
      dataForm,
      insert,
      spinner,
      header,
      loading,
      arrayDataOrder,
      format,
      money,
      arrayReturnProducts,
      addProducts,
      removeProduct,
      handleQuantityChange,
      totalOfTheOrder,
      subTotalOfTheOrder,
      ivaOfTheOrder,
      selectRow
    };
  },
};
</script>
