<template>
  <section>
    <div class="grid lg:grid-cols-12 gap-6">
      <div
        class="lg:col-span-8 xl:col-span-9 shadow-sm border rounded-md overflow-hidden"
      >
        <h1 class="text-lg font-semibold text-slate-700 p-4">
          Traslado interno de productos
        </h1>

        <div class="p-4">
          <SearchProduct @send-data="addProducts" :typeVoucher="''" />
        </div>

        <div class="mt-4">
          <table class="table-auto w-full">
            <TableHead :headers="header" class="sticky top-0" />
            <tbody>
              <tr
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                v-for="(product, i) in dataForm.details"
                :key="i"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  <input
                    type="number"
                    min="1"
                    :value="product.quantity"
                    class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                    @blur="handleQuantityChange(i, $event, product.igvApplied)"
                    @keyup.enter="
                      handleQuantityChange(i, $event, product.igvApplied)
                    "
                  />
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ product.productName }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  <select
                    class="border-2 bg-gray-50 py-1 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none font-semibold max-w-[120px]"
                    @change="
                      handleUnitMeasureChange(i, $event, product.igvApplied)
                    "
                  >
                    <option
                      :value="
                        unit.precio +
                        '|' +
                        unit.cantidad +
                        '|' +
                        unit.unidad_de_medida
                      "
                      v-for="(unit, index) in product.units"
                      :key="index"
                      :selected="
                        unit.unidad_de_medida === 'UND' ||
                        unit.unidad_de_medida === 'NUI' ||
                        unit.unidad_de_medida === 'UNIDAD' ||
                        unit.unidad_de_medida === 'UNIDADES' ||
                        unit.unidad_de_medida === 'UNID'
                      "
                    >
                      {{ unit.unidad_de_medida }}
                    </option>
                  </select>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }} {{ format(product.salePrice) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase font-bold"
                >
                  {{ money() }} {{ format(product.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-red-100 text-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-500"
                    @click="deleteRecordFromTable(i)"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-trash"
                      class="w-3 h-3"
                    />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div class="p-4">
          <TransferTotals
            :iva="dataForm.igv"
            :subTotal="dataForm.subtotal"
            :total="dataForm.total"
          />
        </div>
      </div>
      <div
        class="lg:col-span-4 xl:col-span-3 rounded-md overflow-hidden border border-gray-100 shadow-sm p-4 flex flex-col justify-between"
      >
        <label class="block w-full">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Sucursales destino
          </span>
          <Stores @send-data="handleStore" />
        </label>

        <button
          class="bg-blue-500 text-white px-3 py-3 font-semibold text-xs rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full justify-center mt-4"
          @click="insert"
          :disabled="spinner === true"
        >
          <div class="flex items-center" v-if="spinner">
            <svg class="w-4 h-4 mr-3 animate-spin">
              <use href="@/assets/svg/icon.svg#spinnerForBlue" />
            </svg>
            Generando traslado
          </div>
          <span v-else>Generar traslado</span>
        </button>
      </div>
    </div>
  </section>
</template>

<script>
import TransferTotals from "@/components/InternalTransferOfProducts/TransferTotals.vue";
import SearchProduct from "@/components/Sales/Searchs/SearchProduct.vue";
import TableHead from "@/components/TablesComponents/TableHead.vue";
import Stores from "@/components/PublicComponents/Stores.vue";
import createdData from "@/components/InternalTransferOfProducts/api/createdData";
import { printVoucherTicket } from "./vouchers/PrintOnTicket";
import validateStockProduct from "./api/validateStockProduct";
import { onMounted, ref } from "vue";
import { percentage } from "@/publicjs/convertToPercentage";
import { errorActions } from "@/alerts";
import { money, format } from "@/publicjs/money";
import { selectRow } from "@/publicjs/selectRowTable";
import {
  idLocalStore,
  idLocalTurn,
  idLocalUser,
} from "@/publicjs/localStorage";
export default {
  name: "MainInternalTransferOfProducts",
  components: {
    TransferTotals,
    SearchProduct,
    TableHead,
    Stores,
  },
  setup() {
    const dataForm = ref({
      idsucursal: 0,
      iddestino: 0,
      idusuario: 0,
      idturno: 0,
      igv: 0,
      subtotal: 0,
      total: 0,
      details: [],
    });
    const spinner = ref(false);

    const header = [
      [
        {
          title: "cantidad",
        },
        {
          title: "producto",
        },
        {
          title: "U.M",
        },
        {
          title: "P.U",
        },
        {
          title: "total",
        },
        {
          title: "acciones",
        },
      ],
    ];
    /*********************************************************************************
     * Funcion para añadir productos a la lista de ventas
     * Los datos del producto biene desde el componente de SearchProduct
     *********************************************************************************/

    const addProducts = async (data) => {
      let {
        discount,
        expired,
        idProduct,
        igv,
        igvApplied,
        insuranceDiscount,
        maximumDiscount,
        poster,
        productName,
        quantity,
        salePrice,
        subTotal,
        taxFree,
        total,
        unitMeasure,
        units,
        quantityContained,
        precio_descuento,
        vencimiento_descuento,
        productSerie,
        warranty_expiration,
        warranty_type,
        shoppingPrice,
      } = data;
      //parseamos el iva del producto
      const CONFIGURE_IGV = percentage(igvApplied);

      subTotal = parseFloat(
        (parseFloat(salePrice) / (CONFIGURE_IGV + 1)).toFixed(2)
      );
      igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));
      total = parseFloat(
        (parseFloat(quantity) * parseFloat(salePrice)).toFixed(2)
      );

      // Buscar el producto en la lista existente
      const existingProductIndex = dataForm.value.details.findIndex(
        (order) =>
          order.idProduct === idProduct && order.unitMeasure === unitMeasure
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct = dataForm.value.details[existingProductIndex];
        const newQuantity = existingProduct.quantity + quantity;

        const thereIsStock = await validateStockProduct(idProduct, newQuantity);

        if (thereIsStock) {
          existingProduct.quantity = newQuantity;
          existingProduct.subTotal = parseFloat(
            (subTotal * newQuantity).toFixed(2)
          );
          existingProduct.igv = parseFloat(
            (existingProduct.subTotal * CONFIGURE_IGV).toFixed(2)
          );
          existingProduct.total = parseFloat(
            (existingProduct.quantity * parseFloat(salePrice)).toFixed(2)
          );
        } else {
          errorActions(
            "Lo sentimos, no pudimos agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
          );
        }
      } else {
        //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden
        const orderData = {
          discount,
          expired,
          idProduct,
          igv,
          igvApplied,
          insuranceDiscount,
          maximumDiscount,
          poster,
          productName,
          quantity,
          salePrice: parseFloat(salePrice),
          subTotal,
          taxFree,
          total,
          unitMeasure,
          units,
          quantityContained,
          precio_descuento: parseFloat(precio_descuento),
          vencimiento_descuento,
          productSerie,
          warranty_expiration,
          warranty_type,
          shoppingPrice,
        };
        //Añadimos el nuevo objeto a la oden
        dataForm.value.details.push(orderData);
      }

      //Llamamos la funcion para calcular la orden y le pasamos como parametro el detalle de la orden
      calculateTheTotal(dataForm.value.details);
    };

    /*********************************************************************************
     * Funcion para calcular el total de la orden, incluyendo el igv, subtotal y total
     *********************************************************************************/
    const calculateTheTotal = (order) => {
      //Nos aseguramos que la variables se inicialicen en cero
      dataForm.value.total = 0;
      dataForm.value.subtotal = 0;
      dataForm.value.igv = 0;

      const DISCOUNT = percentage(0);

      //Recorremos todo el detalle de la orden
      order.forEach((element) => {
        let total = 0;
        let subTotal = 0;
        let igv = 0;
        //EL monto de descuento a descontar al total
        const totalDiscount = element.total * DISCOUNT;
        //parseamos el iva del producto
        const CONFIGURE_IGV = percentage(element.igvApplied);

        total = parseFloat(
          (parseFloat(element.total) - totalDiscount).toFixed(2)
        );
        subTotal = parseFloat((total / (CONFIGURE_IGV + 1)).toFixed(2));
        igv = parseFloat((parseFloat(subTotal) * CONFIGURE_IGV).toFixed(2));

        dataForm.value.total += total;
        dataForm.value.subtotal += subTotal;
        dataForm.value.igv += igv;
      });
    };

    /*********************************************************************************
     * Funcion para cambiar la cantidad de la orden
     *********************************************************************************/
    const handleQuantityChange = async (index, event, igvApplied) => {
      const newQuantity =
        parseFloat(event.target.value) !== ""
          ? parseFloat(event.target.value)
          : 1.0;

      const quantityForValidate =
        newQuantity * dataForm.value.details[index].quantityContained;

      const thereIsStock = await validateStockProduct(
        dataForm.value.details[index].idProduct,
        quantityForValidate
      );
      if (thereIsStock) {
        dataForm.value.details[index].quantity = newQuantity;
        updateDetailData(index, igvApplied);
      } else {
        errorActions(
          "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
        );
        event.target.value = dataForm.value.details[index].quantity;
        return;
      }
    };

    /*********************************************************************************
     * Funcion para aplicar el descuento al producto producto
     *********************************************************************************/
    const handleUnitMeasureChange = async (index, event, igvApplied) => {
      const [price, quantityContained, unitMeasure] =
        event.target.value.split("|");

      const product = dataForm.value.details[index];
      //Calculamos la cantidad de productos
      const quantity = product.quantity * parseFloat(quantityContained);

      //Validamos que el producto cuente con stock suficiente para ser cambiado de unidad de medida
      const thereIsStock = await validateStockProduct(
        product.idProduct,
        quantity
      );

      if (thereIsStock) {
        product.salePrice = price;
        product.unitMeasure = unitMeasure;
        product.quantityContained = parseFloat(quantityContained);
        updateDetailData(index, igvApplied);
      } else {
        errorActions(
          "Lo sentimos, no pudimo agregar el producto debido a que <strong>no cuentas con el stock suficiente</strong> <br> Actualiza el stock del producto y vuelve a intentarlo"
        );
        event.target.selectedIndex = 0;
        return;
      }
    };

    /*********************************************************************************
     * Funcion para actualizar la orden
     *********************************************************************************/
    const updateDetailData = (index, igvApplied) => {
      const order = dataForm.value.details[index];

      const totalOrder = order.quantity * order.salePrice;

      //Conversion de descuento a un formato aplicable
      const DISCOUNT = percentage(order.discount);
      const CONFIGURE_IGV = percentage(igvApplied);

      const discountAmount = parseFloat((totalOrder * DISCOUNT).toFixed(2));

      const newTotal = totalOrder - discountAmount;
      const newSubTotal = newTotal / (CONFIGURE_IGV + 1);
      const newIgv = newSubTotal * CONFIGURE_IGV;

      //Volvemos a calcular el total  del producto seleccionado
      order.subTotal = parseFloat(newSubTotal.toFixed(2));
      order.igv = parseFloat(newIgv.toFixed(2));
      order.total = parseFloat(newTotal.toFixed(2));

      calculateTheTotal(dataForm.value.details);
    };

    /*********************************************************************************
     * Funcion para eliminar un registro del arreglo de la orden
     *********************************************************************************/
    const deleteRecordFromTable = (index) => {
      dataForm.value.details.splice(index, 1);
      //Llamamos la funcion para calcular la orden  y le pasamos como parametro el detalle de la orden
      calculateTheTotal(dataForm.value.details);
    };

    const handleStore = (data) => {
      dataForm.value.iddestino = data;
    };

    const insert = async () => {
      if (dataForm.value.details.length <= 0) {
        errorActions(
          "Por favor ingresa uno o mas productos para poder realizar el traslado."
        );
        return;
      }
      if (dataForm.value.iddestino <= 0) {
        errorActions("Selecciona un sucursal de destino para el traslado.");
        return;
      }
      if (dataForm.value.idsucursal === dataForm.value.iddestino) {
        errorActions(
          "No se puede hacer el traslado, debido a que el sucursal destino es igual al sucursal de origen.<br><br> <strong>Selecciona otra sucursal y vuelve a intentarlo de nuevo.</strong>"
        );
        return;
      }
      spinner.value = true;
      const { id, status } = await createdData(dataForm.value);
      if (status === true) {
        await printVoucherTicket(id);
        dataForm.value.details = [];
        dataForm.value.igv = 0;
        dataForm.value.subtotal = 0;
        dataForm.value.total = 0;
      }

      spinner.value = false;
    };

    onMounted(async () => {
      dataForm.value.idsucursal = await idLocalStore();
      dataForm.value.idusuario = await idLocalUser();
      dataForm.value.idturno = await idLocalTurn();
    });

    return {
      dataForm,
      addProducts,
      header,
      money,
      format,
      handleQuantityChange,
      handleUnitMeasureChange,
      deleteRecordFromTable,
      handleStore,
      insert,
      spinner,
      selectRow
    };
  },
};
</script>
