<template>
  <div class="fixed z-10 inset-0 overflow-y-auto" id="modalContainer">
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl z-50 px-6 py-6 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] md:max-w-[80vw]"
      >
        <div class="flex justify-between items-center mb-6">
          <h2 class="text-xl font-medium text-gray-800 dark:text-white">
            Unidades de medida del producto <strong class="text-green-500">P. compra: ${{ purchasePrice }}</strong>
          </h2>
          <button
            @click="isActive"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="../../../../assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <!-- registro de mas unidades de medida -->
        <div class="border rounded-lg mb-4">
          <div
            class="px-3 py-3 bg-gray-100 grid md:grid-cols-2 lg:grid-cols-4 gap-4  "
          >
            <label class="block w-full">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                U.M
              </span>
              <UnitMeasure @send-data="getSelectUnitMeasure" />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Cantidad contenido <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.quantity"
                placeholder="Cantidad contenidad en la unidad"
                @keydown.enter.prevent
              />
            </label>
            <button
              class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center h-10 mt-7 justify-center"
              @click="insert"
              :disabled="spinner === true"
            >
              <div class="flex items-center" v-if="spinner">
                <svg class="w-4 h-4 mr-3 animate-spin">
                  <use href="../../../../assets/svg/icon.svg#spinnerForBlue" />
                </svg>
                Registrando Información
              </div>
              <span v-else>Registrar</span>
            </button>
          </div>
          <div
            class="grid md:grid-cols-2 lg:grid-cols-6 gap-4 px-3 py-3 bg-gray-100"
          >
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio principal <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.price"
                placeholder="Precio por la unidad"
                @keydown.enter.prevent
                @input="calculateUtilityFromSalePriceOne"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Utilidad principal %<span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.utility"
                placeholder="Utilidad"
                @keydown.enter.prevent
                @input="calcUtilityPriceOne"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio 2 <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.price_two"
                placeholder="Precio por la unidad"
                @keydown.enter.prevent
                @input="calculateUtilityFromSalePriceTwo"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Utilidad 2  %<span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.utility_price_two"
                placeholder="Utilidad"
                @keydown.enter.prevent
                @input="calcUtilityPriceTow"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Precio 3 <span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.price_three"
                placeholder="Precio por la unidad"
                @keydown.enter.prevent
                @input="calculateUtilityFromSalePriceThree"
              />
            </label>
            <label class="block">
              <span
                class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
              >
                Utilidad 3  %<span class="text-red-300">*</span>
              </span>
              <input
                type="number"
                class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
                v-model="dataForm.utility_price_three"
                placeholder="Utilidad"
                @keydown.enter.prevent
                @input="calcUtilityPriceThree"
              />
            </label>
          </div>
        </div>
        <div>
          <div class="overflow-x-auto">
            <table class="table-auto w-full">
              <TableHead :headers="header" />
              <LoadingTables v-if="isLoading" :columns="9" />
              <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                <tr
                  v-for="(register, i) in registers"
                  :key="i"
                  class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                >
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    {{ register.um }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.cantidad"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.precio"
                      @input="calcUtilityFromSalePriceOne(i)"
                      :disabled="i ===  registers.length -1"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.utilidad"
                      :disabled="i === registers.length -1"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.precio_dos"
                      @input="calcUtilityFromSalePriceTwo(i)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.utilidad_precio_dos"
                      readonly
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.precio_tres"
                      @input="calcUtilityFromSalePriceThree(i)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    <input
                      type="text"
                      class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 hover:bg-blue-400 hover:border-blue-500 hover:text-white min-w-[5rem]"
                      v-model="register.utilidad_precio_tres"
                      readonly
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                  >
                    <button
                      class="bg-red-100 text-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-600 mr-2"
                      @click="isDelete(register.um, register.id)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-trash"
                        class="w-3 h-3"
                      />
                    </button>
                    <button
                      class="bg-blue-100 text-blue-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-600 mr-2"
                      @click="updateUnitMeasure(i)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-pen"
                        class="w-3 h-3"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import { observer } from "@/observer";
import axios from "../../../../api";
import Swal from "sweetalert2";

import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import UnitMeasure from "@/components/PublicComponents/UnitMeasure.vue";
import { percentage } from "@/publicjs/convertToPercentage";
import { selectRow } from "@/publicjs/selectRowTable";

export default {
  name: "UpdateProducts",
  components: {
    LoadingTables,
    TableHead,
    UnitMeasure,
  },
  props: {
    activeModal: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    purchasePrice: {
      type: Number,
      required: false,
    },
  },
  setup(props) {
    async function isActive() {
      await props.activeModal(true);
    }

    const spinner = ref(false);

    const header = [
      [
        {
          title: "U.M",
        },
        {
          title: "Cantidad",
        },
        {
          title: "precio principal",
        },
        {
          title: "utilidad principal",
        },
        {
          title: "precio 2",
        },
        {
          title: "utilidad 2",
        },
        {
          title: "precio 3",
        },
        {
          title: "utilidad 3",
        },
        {
          title: "acciones",
        },
      ],
    ];

    const dataForm = ref({
      quantity: 0,
      price: 0,
      utility: 0,
      price_two: 0,
      utility_price_two: 0,
      price_three: 0,
      utility_price_three: 0,
      idUnitMeasure: null,
    });
    const dataFormUpdate = ref({
      quantity: 0,
      price: 0,
    });

    //recepcion de datos desde el componente publico, todas las unidades de medida
    const getSelectUnitMeasure = (data) => {
      dataForm.value.idUnitMeasure = data;
    };

    //variable para guardar los registros de la base de datos
    const registers = ref([]);
    // el isLoading se aplica para validar que la api aya sido consumido o cargado al 100 y recien ahi cargamos el componente
    const isLoading = ref(true);

    const getData = async () => {
      isLoading.value = true;
      const response = await axios
        .get(
          "productos-unidad-de-medida/?page=1&per_page=100&idproduct=" +
            props.id
        )
        .catch((error) => errorActions(error));
      registers.value = await response.data.data;
      isLoading.value = false;
    };

    const insert = async () => {
      if (dataForm.value.idUnitMeasure === null) {
        errorActions("Selecciona una unidad de medida para poder registrar");

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.price <= 0) {
        errorActions(
          "Ingresa el precio de venta por la unidad de medida del producto"
        );

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (dataForm.value.quantity <= 0) {
        errorActions("Ingresa la cantidad que contiene la unidad de medida");

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      spinner.value = true;
      const response = await axios
        .post(
          "productos-unidad-de-medida",
          {
            idProduct: props.id,
            idUnitMeasure: dataForm.value.idUnitMeasure,
            quantity: dataForm.value.quantity,
            price: dataForm.value.price,
            utility: dataForm.value.utility,
            price_two: dataForm.value.price_two,
            utility_price_two: dataForm.value.utility_price_two,
            price_three: dataForm.value.price_three,
            utility_price_three: dataForm.value.utility_price_three,
            state: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `La unidad de medida  <strong>del producto a sido añadida</strong>`
        );
        dataForm.value.price = 0;
        dataForm.value.quantity = 0;
        await getData();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos registrar la <strong>unidad de medida</strong>`
        );
      }
      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    const updateUnitMeasure = async (id) => {
      if (registers.value[id].price <= 0) {
        errorActions(
          "Ingresa el precio de venta por la unidad de medida del producto"
        );

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      if (registers.value[id].quantity <= 0) {
        errorActions("Ingresa la cantidad que contiene la unidad de medida");

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      const response = await axios
        .put(
          "productos-unidad-de-medida/" + registers.value[id].id,
          {
            quantity: registers.value[id].cantidad,
            price: registers.value[id].precio,
            utility: registers.value[id].utilidad,
            price_two: registers.value[id].precio_dos,
            utility_price_two: registers.value[id].utilidad_precio_dos,
            price_three: registers.value[id].precio_tres,
            utility_price_three: registers.value[id].utilidad_precio_tres,
            state: 1,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfRecord(
          `La unidad de medida  <strong>del producto a sido modificada</strong>`
        );

        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await getData();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else {
        errorActions(
          `Lo sentimos, no pudimos modificar la <strong>unidad de medida</strong>`
        );
      }
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    const calcUtilityFromSalePriceOne = async (index) => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = registers.value[index].precio;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      registers.value[index].utilidad = Math.round(porcentajeGanancia);
    };
    const calcUtilityFromSalePriceTwo = async (index) => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = registers.value[index].precio_dos;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      registers.value[index].utilidad_precio_dos =
        Math.round(porcentajeGanancia);
    };
    const calcUtilityFromSalePriceThree = async (index) => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = registers.value[index].precio_tres;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      // Redondear el porcentaje a un número entero
      registers.value[index].utilidad_precio_tres =
        Math.round(porcentajeGanancia);
    };


     //funcion para calcular el precio 1 del producto
     const calcUtilityPriceOne = () => {
      let salePrice = 0;
      const purchasePrice = parseFloat(props.purchasePrice);
      const totalSales = purchasePrice * percentage(dataForm.value.utility);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataForm.value.price = salePrice.toFixed(2);
    };
    //funcion para calcular el precio 2 del producto
    const calcUtilityPriceTow = () => {
      let salePrice = 0;
      const purchasePrice = parseFloat(props.purchasePrice);
      const totalSales = purchasePrice * percentage(dataForm.value.utility_price_two);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10;
      dataForm.value.price_two = salePrice.toFixed(2);
    };
    //funcion para calcular el precio 3 del producto
    const calcUtilityPriceThree = () => {
      let salePrice = 0;
      const purchasePrice = parseFloat(props.purchasePrice);
      const totalSales = purchasePrice * percentage(dataForm.value.utility_price_three);
      salePrice = Math.ceil((purchasePrice + totalSales) * 10) / 10; 
      dataForm.value.price_three = salePrice.toFixed(2);
    };

    const calculateUtilityFromSalePriceOne = async () => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = dataForm.value.price;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;

      dataForm.value.utility = Math.round(porcentajeGanancia);
    };
    const calculateUtilityFromSalePriceTwo = async () => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = dataForm.value.price_two;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;
      dataForm.value.utility_price_two = Math.round(porcentajeGanancia);
    };
    const calculateUtilityFromSalePriceThree = async () => {
      let shoppinPrice = props.purchasePrice;
      let salePrice = dataForm.value.price_three;
      let porcentajeGanancia = 0;
      porcentajeGanancia = ((salePrice - shoppinPrice) / shoppinPrice) * 100;
      dataForm.value.utility_price_three = Math.round(porcentajeGanancia);
    };



    onMounted(async () => {
      await getData();

      observer.observe(
        document.getElementById("modalContainer"),
        { box: "content-box" },
        10
      );
    });

    //Acciones para eliminar
    const deleteData = async (register, id) => {
      const response = await axios
        .delete("productos-unidad-de-medida/" + id, {
          headers: {
            "Content-Type": "application/json",
          },
        })
        .catch(function (error) {
          errorActions(error);
        });
      const data = response.data;
      if (data.msg === "Request failed with status code 500") {
        errorActions(data.msg);
      } else if (data.msg === false) {
        errorActions(
          `Lo sentimos no pudimos eliminar el registro <strong>${register}</strong>`
        );
      }
    };
    //SweetAlert para confirmas la eliminacion del registro
    const isDelete = (register, id) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el registro?",
        html: `Si eliminas <strong>${register}</strong>, ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          await deleteData(register, id);
          Swal.fire({
            icon: "success",
            title: "Eliminado",
            html: `<strong>${register}</strong> a sido eliminado de la base de datos`,
            confirmButtonColor: "#315df5",
            confirmButtonText: "Aceptar!",
          });

          await getData();
        }
      });
    };
    return {
      isActive,
      isLoading,
      insert,
      header,
      registers,
      dataForm,
      getSelectUnitMeasure,
      spinner,
      updateUnitMeasure,
      dataFormUpdate,
      isDelete,
      calcUtilityFromSalePriceOne,
      calcUtilityFromSalePriceTwo,
      calcUtilityFromSalePriceThree,
      calculateUtilityFromSalePriceOne,
      calculateUtilityFromSalePriceTwo,
      calculateUtilityFromSalePriceThree,
      calcUtilityPriceOne,
      calcUtilityPriceTow,
      calcUtilityPriceThree,
      selectRow
    };
  },
};
</script>
