<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <button
        class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="activeModalRegister"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
        Nuevo registro
      </button>
      <!-- acciones de impresion -->
      <div class="relative group">
        <button
          class="bg-blue-100 flex items-center justify-center w-28 h-9 rounded-md text-blue-600"
        >
          Opciones
          <font-awesome-icon
            icon="fa-solid fa-ellipsis"
            class="rotate-90 ml-2"
          />
        </button>
        <ul
          class="absolute top-full right-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100"
        >
          <button
            class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
            @click="exportPDF(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a PDF
          </button>
          <button
            class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
            @click="exportToExcel(registers)"
          >
            <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
            Exportar a excel
          </button>
        </ul>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 flex items-center whitespace-nowrap uppercase"
                >
                  <div class="w-8 h-8 mr-3">
                    <div class="w-8 h-8 rounded-full overflow-hidden">
                      <img
                        src="@/assets/images/defaults/avatar-default.png"
                        :alt="register.nombres"
                        v-if="register.avatar === null"
                      />
                      <img
                        :src="APIURL + register.avatar"
                        :alt="register.nombres"
                        v-else
                      />
                    </div>
                  </div>
                  {{
                    register.nombres.length >= 10
                      ? register.nombres.slice(0, 10) + "***"
                      : register.nombres
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase bg-blue-50 text-blue-500"
                >
                  {{ money() }} {{ format(register.monto) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{
                    register.numero_desembolso !== null
                      ? register.numero_desembolso
                      : "---"
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.beneficiario !== null
                      ? register.beneficiario
                      : "---"
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span v-if="register.departamento !== null">
                    {{
                      register.departamento.length >= 10
                        ? register.departamento.slice(0, 10) + "***"
                        : register.departamento
                    }}
                  </span>
                  <span v-else> --- </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{
                    register.concepto.length >= 15
                      ? register.concepto.slice(0, 15) + "***"
                      : register.concepto
                  }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ formatDateTime(register.fecha_de_egreso) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 p-1 rounded-sm"
                    :class="{
                      'bg-orange-50 text-orange-500':
                        register.estado === 'PENDIENTE',
                      'bg-green-50 text-green-500':
                        register.estado === 'ACEPTADO',
                      'bg-red-50 text-red-500': register.estado === 'RECHAZADO',
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="activeModalShow(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-eye"
                          class="mr-2"
                        />
                        Ver desembolso
                      </button>
                      <button
                        class="px-6 py-2 text-blue-500 flex items-center hover:bg-blue-50 whitespace-nowrap w-full"
                        @click="activeModalUpdate(register.id)"
                        v-if="
                          typeUser === 'ADMINISTRADOR' ||
                          typeUser === 'SUPER ADMIN' ||
                          typeUser === 'ADMINROOT'
                        "
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-pen"
                          class="mr-2"
                        />
                        Modificar
                      </button>
                      <button
                        class="px-6 py-2 text-red-500 flex items-center hover:bg-red-50 whitespace-nowrap w-full"
                        @click="deleteDisbursementData(register.id,(money()+format(register.monto)))"
                        v-if="
                          typeUser === 'ADMINISTRADOR' ||
                          typeUser === 'SUPER ADMIN' ||
                          typeUser === 'ADMINROOT'
                        "
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-trash"
                          class="mr-2"
                        />
                        Eliminar
                      </button>
                      <div
                        v-if="
                          typeUser === 'ADMINISTRADOR' ||
                          typeUser === 'SUPER ADMIN' ||
                          typeUser === 'ADMINROOT'
                        "
                      >
                        <button
                          class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
                          v-if="register.estado !== 'RECHAZADO'"
                          @click="changeState(register.id, 'RECHAZADO')"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-cancel"
                            class="mr-2"
                          />
                          Rechazar
                        </button>
                        <button
                          class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
                          v-if="register.estado !== 'RECHAZADO'"
                          @click="changeState(register.id, 'ACEPTADO')"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-check"
                            class="mr-2"
                          />
                          Aceptar
                        </button>
                      </div>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterDisbursements
    :activeModal="activeModalRegister"
    :getdata="getData"
    v-if="stateRegister"
  />
  <UpdateDisbursements
    :activeModal="activeModalUpdate"
    :getdata="getData"
    :id="id"
    v-if="stateUpdate"
  />
  <ShowDisbursements
    :activeModal="activeModalShow"
    :getdata="getData"
    :id="id"
    v-if="stateModalShow"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterDisbursements from "@/components/Disbursements/RegisterDisbursements.vue";
import UpdateDisbursements from "@/components/Disbursements/UpdateDisbursements.vue";
import ShowDisbursements from "@/components/Disbursements/ShowDisbursements.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore, localTypeUser } from "@/publicjs/localStorage";
import { formatDateTime } from "@/publicjs/formatDate";
import { selectRow } from "@/publicjs/selectRowTable";

import { changeStateDisbursement } from "@/repository/Disbursements/ChangeStateDisbursements";

import { exportToExcel } from './Reports/ExcelExportAllDisbursements'
import { exportPDF} from './Reports/PDFExportAllDisbursements'
import { deleteDisbursement } from '@/repository/Disbursements/DeleteDisbursements';

export default {
  name: "MainDisbursements",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterDisbursements,
    UpdateDisbursements,
    ShowDisbursements
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "responsable",
        },
        {
          title: "monto",
        },
        {
          title: "n° de desembolso",
        },
        {
          title: "beneficiario",
        },
        {
          title: "departamento",
        },
        {
          title: "concepto",
        },
        {
          title: "fec. desembolso",
        },
        {
          title: "estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0);
    const typeUser = ref("");
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `desembolsos/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${idStore.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //URL PARA LOS RECURSO DE LA API COMO IMAGNES, ARCHIVOS, ETC

    const APIURL = process.env.VUE_APP_API_URL_RESOURCE;

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    const id = ref(0);
    const activeModalUpdate = (idData) => {
      stateUpdate.value = !stateUpdate.value;
      id.value = idData;
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      idStore.value = await idLocalStore();
      typeUser.value = await localTypeUser();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const stateUpdate = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };
    
    //Activar el Show modal del desembolso
    const stateModalShow = ref(false);
    const activeModalShow = (idData) => {
      stateModalShow.value = !stateModalShow.value;
      id.value = idData;
    };


    const changeState = async (id, state) => {
      await changeStateDisbursement(id, state, resetValuesAndGetData);
    };
    const deleteDisbursementData = async (id, state) => {
      await deleteDisbursement(id, state, resetValuesAndGetData);
    };


    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      activeModalUpdate,
      id,
      stateUpdate,
      widthNav,
      handleResize,
      money,
      format,
      APIURL,
      formatDateTime,
      typeUser,
      changeState,
      exportToExcel,
      exportPDF,
      activeModalShow,
      stateModalShow,
      deleteDisbursementData,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
