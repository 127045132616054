<template>
  <div>
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex items-end gap-4 flex-wrap">
        <label class="block mt-3 md:mt-0 w-full md:w-auto">
          <span class="block text-gray-600 font-semibold mb-1 text-sm"
            >Fec. de inicio</span
          >
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block mt-3 md:mt-0 w-full md:w-auto">
          <span class="block text-gray-600 font-semibold mb-1 text-sm"
            >Fec. Término</span
          >
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <button
          class="px-3 py-2 bg-blue-500 hover:bg-blue-600 border-2 border-blue-500 rounded-md hover:border-blue-600 text-sm text-white active:scale-95"
          @click="resetValuesAndGetData"
        >
          Consultar
        </button>
        <button
          class="px-3 py-2 bg-green-500 hover:bg-green-600 border-2 flex item-center gap-2 border-gren¿en-500 rounded-md hover:border-green-600 text-sm text-white active:scale-95"
          @click="checkWarranty"
        >
          <IconWarranty class="w-4 h-4" /> Garantía
        </button>
      </div>
      <div class="flex items-start gap-4 flex-wrap">
        <label class="flex flex-col h-6 mr-5 cursor-pointer mb-3 md:mb-0">
          <span
            class="block mb-2 text-sm font-medium text-gray-600 dark:text-white"
          >
            Factura proforma
          </span>
          <div
            class="relative inline-flex items-center h-6 mr-5 mt-2 cursor-pointer"
          >
            <input
              type="checkbox"
              class="sr-only peer"
              v-model="dataForm.typeVoucher"
              @change="resetValuesAndGetData"
            />
            <div
              class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
            ></div>
            <span
              class="ml-3 text-sm font-medium text-gray-600 dark:text-gray-300"
            >
              {{ dataForm.typeVoucher ? "SI" : "NO" }}
            </span>
          </div>
        </label>
        <label class="block mt-3 md:mt-0 w-full md:w-auto">
          <span class="block text-gray-600 font-semibold mb-1 text-sm"
            >Filtrar por estados</span
          >
          <select
            class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
            @change="resetValuesAndGetData"
            v-model="salesState"
          >
            <option value="EFECTUADA">EFECTUADA</option>
            <option value="ANULADO">ANULADO</option>
            <option value="MODIFICADO">MODIFICADO</option>
            <option value="VENTA A CREDITO">VENTA A CREDITO</option>
          </select>
        </label>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
          <option value="5000">5000 Registros</option>
          <option value="10000">10000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por fecha, nombre, n° de documento del cliente o n° de ref de comprobante..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
              1: Para poner una tabla responsiva añadir lo siguiente:
              1.1: class="resize-generate"
              1.2: ref="widthNav" 
              1.3: @resize="handleResize"
          -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="11" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)"
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.nombre_cliente_pedido }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.tipo_comprobante.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase bg-blue-50 text-blue-500"
                >
                  {{ register.serie_comprobante }}
                  {{ register.numero_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }} {{ format(register.igv) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.subtotal) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap font-bold"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.tipo_de_pago.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-gray-50 text-gray-600':
                        register.tipo_de_venta.descripcion === 'CONTADO',
                      'bg-orange-50 text-orange-600':
                        register.tipo_de_venta.descripcion === 'CREDITO',
                    }"
                  >
                    {{ register.tipo_de_venta.descripcion }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ formatDateTime(register.fecha_de_emision) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-lg"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.estado === 'EFECTUADA',
                      'bg-orange-50 text-orange-600':
                        register.estado === 'ANULADO',
                      'bg-red-50 text-red-600':
                        register.estado === 'MODIFICADO',
                      'bg-blue-50 text-blue-500':
                        register.estado === 'VENTA A CREDITO',
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap flex justify-end"
                >
                  <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <div
                        v-if="register.estado === 'EFECTUADA'"
                        class="bg-blue-100"
                      >
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="
                            activeUpdateSale(register.id, {
                              total: parseFloat(register.total),
                              subtotal: parseFloat(register.subtotal),
                              igv: parseFloat(register.igv),
                              numberVoucher:
                                register.serie_comprobante +
                                '-' +
                                register.numero_comprobante,
                              user: register.usuario.nombres,
                            })
                          "
                          v-if="dataForm.canYouUpdateSale"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-pen"
                            class="mr-2"
                          />
                          Modificar venta {{ register.idsucursal }}
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="activeCreditNote(register.id)"
                          v-if="dataForm.canYouModifisale"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-truck"
                            class="mr-2"
                          />
                          Emitir NC
                        </button>
                      </div>
                      <div
                        class="bg-blue-100"
                        v-if="
                          register.estado === 'EFECTUADA' ||
                          register.estado === 'VENTA A CREDITO'
                        "
                      >
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="activeCreditNote(register.id)"
                          v-if="dataForm.canYouModifisale"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-truck"
                            class="mr-2"
                          />
                          Emitir NC
                        </button>
                        <button
                          class="px-6 py-2 text-red-500 flex items-center hover:bg-red-50 whitespace-nowrap w-full"
                          @click="
                            activeCancelSale(
                              register.id,
                              register.serie_comprobante +
                                '-' +
                                register.numero_comprobante
                            )
                          "
                          v-if="dataForm.canYouCancelTheSale"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-cancel"
                            class="mr-2"
                          />
                          Anular venta
                        </button>
                        <button
                          class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                          @click="
                            activeConvertVoucher(
                              register.id,
                              register.serie_comprobante +
                                '-' +
                                register.numero_comprobante
                            )
                          "
                          v-if="dataForm.canYouConvertProof"
                        >
                          <font-awesome-icon
                            icon="fa-solid fa-file-invoice"
                            class="mr-2"
                          />
                          Convertir comprobante
                        </button>
                      </div>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherTicket(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 80MM
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnA4(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en A4
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 8 1/2x11
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnMediaLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en M. carta
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <CreditNote
    :activeModal="activeCreditNote"
    :getdata="getData"
    :id="dataForm.idSale"
    v-if="stateCreditNote"
  />
  <CancelSales
    :activeModal="activeCancelSale"
    :getdata="getData"
    :id="dataForm.idSale"
    :numberSale="dataForm.numberSale"
    v-if="stateCancelSale"
  />
  <ConvertVoucher
    :activeModal="activeConvertVoucher"
    :getdata="getData"
    :id="dataForm.idSale"
    :numberSale="dataForm.numberSale"
    v-if="stateConvertVoucher"
  />
  <UpdateSale
    :activeModal="activeUpdateSale"
    :getdata="getData"
    :id="dataForm.idSale"
    :infoSale="infoSale"
    v-if="stateUpdateSale"
  />
  <CheckWarranty :activeModal="checkWarranty" v-if="ischeckWarrinty" />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import CreditNote from "@/components/Sales/CreditNote/CreditNote.vue";
import CancelSales from "@/components/Sales/CancelSales/CancelSales.vue";
import ConvertVoucher from "@/components/Sales/ConvertVoucher/ConvertVoucher.vue";
import { errorActions, warningActions } from "@/alerts";
import { observer } from "@/observer";
import {
  idLocalStore,
  idLocalUser,
  localTypeUser,
  localUserName,
} from "@/publicjs/localStorage";
import { money, format } from "@/publicjs/money";
import { formatDateTime } from "@/publicjs/formatDate";

import config from "/public/apis/config.json";
import CheckWarranty from "@/components/Sales/CheckWarranty.vue";

// Print voucher
import { printVoucherOnA4 } from "./PrintVoucher/PrintOnA4";
import { printVoucherOnLetter } from "./PrintVoucher/PrintOnLetter";
import { printVoucherOnMediaLetter } from "./PrintVoucher/PrintOnMediaLetter";
import { printVoucherTicket } from "./PrintVoucher/PrintOnTicket";
import { validateAccessSpecific } from "@/repository/Sales/ValidateAccessEspecific/ValidateAccess";
import IconWarranty from "@/components/icons/IconWarranty.vue";

import UpdateSale from "@/components/Sales/UpdateSale.vue";
import { selectRow } from "@/publicjs/selectRowTable";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { currentDay } from "@/publicjs/currentDate";

export default {
  name: "MainListSales",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    CreditNote,
    CancelSales,
    ConvertVoucher,
    UpdateSale,
    IconWarranty,
    CheckWarranty,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "cliente",
        },
        {
          title: "comprobante",
        },
        {
          title: "n° comprobante",
        },
        {
          title: config.iva,
        },
        {
          title: "subtotal",
        },
        {
          title: "total",
        },
        {
          title: "Tipo de pago",
        },
        {
          title: "Tipo de venta",
        },
        {
          title: "Fecha de emisión",
        },
        {
          title: "Estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const dataForm = ref({
      typeVoucher: false,
      dateStart: null,
      dateEnd: null,
      idStore: 0,
      idSale: 0,
      numberSale: "",
      canYouCancelTheSale: false,
      canYouModifisale: false,
      canYouConvertProof: false,
      canYouUpdateSale: false,
      userName: null,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const salesState = ref("EFECTUADA");
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `ventas/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${dataForm.value.idStore}&state=${salesState.value}&type_voucher=${dataForm.value.typeVoucher}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(() => {
        if (widthNav.value) {
          widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
        }
      });
    };

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateCreditNote = ref(false);
    const activeCreditNote = (id) => {
      dataForm.value.idSale = id;
      stateCreditNote.value = !stateCreditNote.value;
    };
    //activar modal de cancelar venta
    const stateCancelSale = ref(false);
    const activeCancelSale = (id, numberSale) => {
      dataForm.value.idSale = id;
      dataForm.value.numberSale = numberSale;
      stateCancelSale.value = !stateCancelSale.value;
    };
    //activar modal de convertir comprobante
    const stateConvertVoucher = ref(false);
    const activeConvertVoucher = (id, numberSale) => {
      dataForm.value.idSale = id;
      dataForm.value.numberSale = numberSale;
      stateConvertVoucher.value = !stateConvertVoucher.value;
    };

    const stateUpdateSale = ref(false);
    const infoSale = ref({});
    const typeOfUser = ref("");
    const activeUpdateSale = (id, info) => {
      dataForm.value.idSale = id;
      infoSale.value = info;

      //VALIDAR ADMINISTRADOR
      if (
        typeOfUser.value !== "ADMINISTRADOR" &&
        typeOfUser.value !== "GERENTE" &&
        typeOfUser.value !== "ENCARGADO" &&
        typeOfUser.value !== "GERENTE GENERAL"
      ) {
        if (infoSale.value.user !== dataForm.value.userName) {
          errorActions(
            "Lo sentimos no podemos habilitar la opcion a modificar venta <br> <strong>debido a que no fuiste tú quien hizo la venta</strong> "
          );
          return;
        }
      }
      stateUpdateSale.value = !stateUpdateSale.value;
    };

    const ischeckWarrinty = ref(false);
    const checkWarranty = () => {
      ischeckWarrinty.value = !ischeckWarrinty.value;
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
      dataForm.value.userName = await localUserName();
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay()
      dataForm.value.dateEnd =dates.lastDay
      
      const ACCESS = [
        "ANULAR VENTA",
        "MODIFICAR VENTAS",
        "GENERAR NOTA DE CREDITO DE VENTAS",
        "CONVERTIR PROFORMA A COMPROBANTE VALIDO"
      ];

      const validate = await validateAccessSpecific(
        ACCESS,
        await idLocalUser()
      );

      if (validate.includes('ANULAR VENTA')) {
        dataForm.value.canYouCancelTheSale = true;
      }
      if (validate.includes('MODIFICAR VENTAS')) {
        dataForm.value.canYouUpdateSale = true;
      }
      if (validate.includes('GENERAR NOTA DE CREDITO DE VENTAS')) {
        dataForm.value.canYouModifisale = true;
      }
      if (validate.includes('CONVERTIR PROFORMA A COMPROBANTE VALIDO')) {
        dataForm.value.canYouConvertProof = true;
      }
      typeOfUser.value = await localTypeUser();
      await getData();
    });
    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeCreditNote,
      stateCreditNote,
      widthNav,
      handleResize,
      money,
      format,
      formatDateTime,
      dataForm,
      salesState,
      activeCancelSale,
      stateCancelSale,
      stateConvertVoucher,
      activeConvertVoucher,
      printVoucherOnA4,
      printVoucherOnLetter,
      printVoucherOnMediaLetter,
      printVoucherTicket,
      stateUpdateSale,
      activeUpdateSale,
      infoSale,
      selectRow,
      ischeckWarrinty,
      checkWarranty,
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
.w-full.resize-x.resize-generate,
.w-full.resize-x.resize-generate .overflow-x-auto {
  min-height: 380px;
}
</style>
