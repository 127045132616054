<template>
  <div class="mt-4">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <!-- Acciones o filtros -->
      <!-- componente para mfiltrar por estado de orden de compra -->
      <label class="block mt-3 md:mt-0 w-full md:w-auto">
        <span class="block text-gray-600 font-semibold mb-1"
          >Filtrar por estados</span
        >
        <select
          v-model="stateAcountsPayable"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full"
          @change="resetValuesAndGetData"
        >
          <option value="0">POR COBRAR</option>
          <option value="1">PAGADOS</option>
        </select>
      </label>
      <div>
        <button class="bg-orange-100 text-orange-500 hover:bg-orange-600 hover:text-white px-3 py-2 rounded-md text-sm font-semibold active:scale-95 inline-block mr-2" @click="accountsReceivableStatement(registers)" 
        v-show="stateExport && search !==''">
          Generar estado de cuenta
        </button>
        <button
        class="bg-blue-100 text-blue-500 hover:bg-blue-500 hover:text-white px-3 py-2 rounded-md text-sm font-semibold active:scale-95"
        @input="stateExport = false"
        @click="activeRecordBulkPayments"
      >
        Pagar en masa
      </button>
      </div>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre, n° de documento o n° de comprobante..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <!-- seccion de conteido de datos tablas
                  1: Para poner una tabla responsiva añadir lo siguiente:
                    1.1: class="resize-generate"
                    1.2: ref="widthNav" 
                    1.3: @resize="handleResize"
                  -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto" id="contenDataTable">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <label
                    class="flex flex-col h-6 cursor-pointer"
                    v-if="register.estado === 0"
                  >
                    <div
                      class="relative inline-flex items-center h-6 cursor-pointer"
                    >
                      <input
                        type="checkbox"
                        class="sr-only peer"
                        @change="
                          toggleDebt({
                            id: register.id,
                            amount: register.saldo,
                          })
                        "
                      />
                      <div
                        class="w-11 h-6 bg-gray-300 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-1 after:left-[4px] after:bg-white after:border-transparent after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-green-500"
                      ></div>
                    </div>
                  </label>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombre_cliente_pedido }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.serie_comprobante }} -
                  {{ register.numero_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ money() }} {{ format(register.total_de_venta) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.cuota_inicial) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-2 py-1 rounded-md"
                    :class="{
                      'bg-orange-50 text-orange-500':
                        parseFloat(register.saldo) > 0,
                    }"
                  >
                    {{ money() }} {{ format(register.saldo) }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 rounded-md text-sm"
                    :class="{
                      'bg-orange-50 text-orange-600': calculateExpirationDate(
                        register.fecha_de_pago
                      ).includes('VENCE EN'),
                      'bg-orange-500 text-white': calculateExpirationDate(
                        register.fecha_de_pago
                      ).includes('VENCE MAÑANA'),
                      'bg-red-50 text-red-600': calculateExpirationDate(
                        register.fecha_de_pago
                      ).includes('VENCE HOY'),
                      'bg-red-500 text-white': calculateExpirationDate(
                        register.fecha_de_pago
                      ).includes('VENCIDO'),
                      'bg-green-50 text-green-600':
                        calculateExpirationDate(
                          register.fecha_de_pago
                        ).includes('-') ||
                        calculateExpirationDate(
                          register.fecha_de_pago
                        ).includes('/'),
                    }"
                  >
                    {{ calculateExpirationDate(register.fecha_de_pago) }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  <span
                    class="px-3 py-1 text-sm rounded-lg"
                    :class="{
                      'bg-red-50 text-red-600': register.estado === 0,
                      'bg-green-50 text-green-600': register.estado === 1,
                    }"
                  >
                    {{ register.estado === 1 ? "PAGADO" : "POR PAGAR" }}
                  </span>
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                  <button
                    class="bg-blue-500 text-white hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-blue-700"
                    @click="activeModalRegister(register.id)"
                    v-if="register.estado === 0"
                  >
                    <font-awesome-icon
                      icon="fa-solid fa-dollar-sign"
                      class="w-3 h-3"
                    />
                    Aplicar pago
                  </button>
                  <span v-else> Ninguna... </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterPayment
    :activeModal="activeModalRegister"
    :id="id"
    :getdata="getData"
    v-if="stateRegister"
  />
  <RecordBulkPayments
    :activeModal="activeRecordBulkPayments"
    :getdata="getData"
    :payments="selectedDebts"
    v-if="stateRecordBulkPayments"
    :clearListPayment="clearSelectedDebts"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "../../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterPayment from "@/components/Sales/AccountsReceivable/RegisterPayment.vue";
import RecordBulkPayments from "@/components/Sales/AccountsReceivable/RecordBulkPayments.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement, observer } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import { calculateExpirationDate } from "@/publicjs/calculateProductExpiration";
import accountsReceivableStatement from "./pdf/accountsReceivableStatement";
import { selectRow } from "@/publicjs/selectRowTable";
export default {
  name: "MainAccountsReceivable",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterPayment,
    RecordBulkPayments,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "---",
        },
        {
          title: "cliente",
        },
        {
          title: "n° de venta",
        },
        {
          title: "total de venta",
        },
        {
          title: "adelanto",
        },
        {
          title: "saldo",
        },
        {
          title: "fecha de pago",
        },
        {
          title: "estado",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(50);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const idStore = ref(0);
    const stateAcountsPayable = ref(0);
    const stateExport = ref(false)
    const getData = async () => {
      stateExport.value = false
      loading.value = true;
      const response = await axios
        .get(
          `cuentas-por-cobrar/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&state=${stateAcountsPayable.value}&idstore=${idStore.value}`
        )
        .catch((error) => errorActions(error));

      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
        //codigo para evitar el error de [resize observer]
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
      if(search.value!=='')stateExport.value = true;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    const id = ref(0);
    const stateRegister = ref(false);
    const activeModalRegister = (idData) => {
      id.value = idData;
      stateRegister.value = !stateRegister.value;
    };

    onMounted(async () => {
      handleResize();
      window.addEventListener("resize", handleResize);
      observeElement("#contenDataTable");

      idStore.value = await idLocalStore();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    const selectedDebts = ref([]); // Variable reactiva para almacenar las deudas seleccionadas
    //Funcion para seleccionar la deuda
    const toggleDebt = (debt) => {
      const index = selectedDebts.value.findIndex(
        (item) => item.id === debt.id
      );

      if (index !== -1) {
        // Si el pago ya existe en la lista de pagos seleccionados, lo quitamos y activamos el input
        selectedDebts.value.splice(index, 1);
      } else {
        // Si el pago no existe en la lista de pagos seleccionados, lo agregamos y desactivamos el input
        selectedDebts.value.push(debt);
      }
    };
    const stateRecordBulkPayments = ref(false);
    const activeRecordBulkPayments = () => {
      if (selectedDebts.value.length <= 0) {
        errorActions(
          "Lo sentimos no podemos proceder a pagar <strong> las deudas debido a que no has seleccionado ninguna cuenta por cobrar del cliente</strong>"
        );
        return;
      }
      stateRecordBulkPayments.value = !stateRecordBulkPayments.value;
    };

    const clearSelectedDebts = async () => {
      selectedDebts.value = [];
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      id,
      widthNav,
      handleResize,
      money,
      format,
      stateAcountsPayable,
      calculateExpirationDate,
      toggleDebt,
      selectedDebts,
      activeRecordBulkPayments,
      stateRecordBulkPayments,
      clearSelectedDebts,
      accountsReceivableStatement,
      stateExport,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
