<template>
    <DailytReportByClient />
  </template>
  
  <script>
  import DailytReportByClient from "@/components/Reports/Sales/DailyReport/DailytReportByClient.vue";
  import { useRoute } from "vue-router";
  import {  onMounted } from "vue";
  export default {
    name: "MainDailyReport",
    components: {
        DailytReportByClient,
    },
    setup() {
      const route = useRoute();
  
      onMounted(() => {
        document.title =
          route.meta.title + process.env.VUE_APP_TITLE ||
          process.env.VUE_APP_TITLE;
      });
    },
  };
  </script>
  