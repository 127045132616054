<template>
  <div>
    <div class="grid md:grid-cols-12 gap-6">
      <div class="md:col-span-8 shadow-sm border rounded-md overflow-hidden">
        <h2 class="text-gray-700 font-semibold px-3 py-3 border-b">
          Detalles de orden de compra
        </h2>
        <div class="px-3 py-3">
          <div>
            <div class="relative">
              <div class="flex items-center relative text-gray-600">
                <div class="absolute top-0 left-3 w-6 h-full flex items-center">
                  <font-awesome-icon
                    icon="fa-solid fa-barcode"
                    class="w-4 h-4"
                  />
                </div>
                <input
                  type="search"
                  v-model="searchProduct"
                  class="border pl-9 py-2 text-sm outline-none w-full rounded-md bg-gray-50"
                  placeholder="Buscar producto por nombre o codigo de barra"
                  @keydown.enter="searchProducts"
                  @keydown.enter.prevent
                />
              </div>
              <div
                v-if="showResultsProducts"
                class="absolute top-[2.3rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg max-h-32 overflow-y-auto"
              >
                <div v-if="loadingSearchProducts">
                  <div class="px-3">
                    <div
                      class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                    ></div>
                    <div
                      class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                    ></div>
                  </div>
                </div>
                <div v-else>
                  <div
                    v-if="!resultLengthProducts"
                    class="px-3 py-1 bg-gray-50 text-gray-400"
                    @click="showResultsProducts = false"
                  >
                    <strong>{{ searchProduct }}</strong> no existe
                  </div>
                  <div
                    v-else
                    v-for="(result, i) in resultSearchProducts"
                    :key="i"
                    class="odd:bg-white even:bg-slate-50"
                  >
                    <div
                      class="py-2 px-3 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500 uppercase"
                      @click="
                        addPurchaseOrderDetails(
                          result.id,
                          result.libre_de_impuesto,
                          'NIU',
                          1,
                          parseFloat(result.precio_de_compra),
                          result.nombre_producto,
                          result.igv
                        )
                      "
                      @blur="showResultsProducts = false"
                    >
                      {{ result.nombre_producto }}
                      <span
                        class="px-3 py-1 rounded-md text-orange-500 bg-orange-50 text-sm font-semibold ml-3"
                      >
                        En stock: {{ result.stock }}
                      </span>
                      <span
                        class="px-3 py-1 rounded-md text-green-500 bg-green-50 text-sm font-semibold ml-3"
                      >
                        P.U: {{ money() }}
                        {{ format(result.precio_de_compra) }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- detalle de la orden de compra -->

          <div class="overflow-x-auto mt-6 max-h-[calc(100vh-210px)]">
            <table class="table-auto w-full">
              <TableHead :headers="header" />
              <LoadingTables v-if="!loading" :columns="8" />
              <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
                <tr
                  class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                  v-for="(order, i) in purchaseOrderDetails"
                  :key="i"
                >
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                  >
                    {{ order.product }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="order.quantity"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                      @input="handleQuantityChange(i, $event, order.ivaAplied)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    <input
                      type="number"
                      min="1"
                      :value="order.unitPrice"
                      class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[80px] rounded-md border-2 border-blue-100 text-center"
                      @input="handlePriceChange(i, $event, order.ivaAplied)"
                    />
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ order.unitMeasure }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ money() }} {{ format(order.subTotal) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                  >
                    {{ money() }} {{ format(order.iva) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase font-bold"
                  >
                    {{ money() }} {{ format(order.total) }}
                  </td>
                  <td
                    class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                  >
                    <button
                      class="bg-red-100 text-red-500 hover:text-white py-1 px-2 rounded-md text-sm active:scale-105 hover:bg-red-500"
                      @click="deletePurchaseOrderDetails(i)"
                    >
                      <font-awesome-icon
                        icon="fa-solid fa-trash"
                        class="w-3 h-3"
                      />
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <PurchaseOrderTotalDetails
            :iva="ivaPurchaseOrder"
            :subTotal="subTotalPurchaseOrder"
            :total="totalPurchaseOrder"
          />
        </div>
      </div>
      <div
        class="md:col-span-4 rounded-md overflow-hidden border border-gray-100 shadow-sm"
      >
        <div
          class="px-3 py-5 bg-blue-500 text-white text-2xl font-semibold text-center"
        >
          {{ money() }} {{ format(totalPurchaseOrder) }}
        </div>

        <div class="px-3 py-3 space-y-4">
          <div class="relative">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Proveedores
            </span>
            <input
              type="text"
              v-model="dataForm.searchSupplier"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 border-gray-200 focus:border-blue-500"
              placeholder="Buscar por razón social o N° de documento..."
              @keydown.enter="searchSupplier"
              @keydown.enter.prevent
            />
            <div
              v-if="showResultsSupplier"
              class="absolute top-[4.2rem] left-0 right-0 z-10 py-2 bg-white border border-gray-300 rounded-md shadow-lg"
            >
              <div v-if="loadingSearchSupplier">
                <div class="px-3">
                  <div
                    class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                  ></div>
                  <div
                    class="px-3 py-3 bg-gray-200 animate-pulse rounded-lg mb-2"
                  ></div>
                </div>
              </div>
              <div v-else>
                <div
                  v-if="!resultLengthSupplier"
                  class="px-3 py-1 bg-gray-50 text-gray-400"
                  @click="showResultsSupplier = false"
                >
                  <strong>{{ dataForm.searchSupplier }}</strong> no existe
                </div>
                <div
                  v-else
                  v-for="(result, i) in resultSearchSupplier"
                  :key="i"
                >
                  <div
                    class="py-1 px-3 mt-1 mb-1 pb-1 text-gray-600 cursor-pointer hover:bg-gray-100 hover:text-blue-500"
                    @click="
                      changeSupplierID(result.id, result.nombre_comercial)
                    "
                    @blur="showResultsSupplier = false"
                  >
                    {{ result.nombre_comercial }}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Fecha de entrega
            </span>
            <input
              type="date"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.deadline"
              placeholder="Fecha de entrega..."
              @keydown.enter.prevent
            />
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Forma de pago
            </span>
            <input
              type="text"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500"
              v-model="dataForm.waToPay"
              placeholder="Forma de pago..."
              @keydown.enter.prevent
              maxlength="30"
            />
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Lugar de entrega
            </span>
            <textarea
              cols="30"
              rows="1"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.placeOfDelivery"
              placeholder="Lugar de entrega..."
              @keydown.enter.prevent
              maxlength="100"
            >
            </textarea>
          </label>
          <label class="block">
            <span
              class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
            >
              Nota adicional
            </span>
            <textarea
              cols="30"
              rows="1"
              class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 resize-none"
              v-model="dataForm.additionalNote"
              placeholder="Nota adicional..."
              @keydown.enter.prevent
              maxlength="100"
            >
            </textarea>
          </label>
          <button
            class="bg-blue-500 text-white px-4 py-3 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center w-full justify-center"
            @click="insert"
            :disabled="spinner === true"
          >
            <div class="flex items-center" v-if="spinner">
              <svg class="w-4 h-4 mr-3 animate-spin">
                <use href="../../../assets/svg/icon.svg#spinnerForBlue" />
              </svg>
              Registrando orden de compra
            </div>
            <span v-else>Registrar orden de compra</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import axios from "../../api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import PurchaseOrderTotalDetails from "./PurchaseOrderTotalDetails.vue";
import { money, format } from "@/publicjs/money";
import axios from "../../../api";
import { observer } from "@/observer";
import { onMounted, ref } from "vue";
import { confirmationOfRecord, errorActions } from "@/alerts";
import config from "/public/apis/config.json";
import { selectRow } from "@/publicjs/selectRowTable";

//* traer el comprobante orden de compra
import { voucherPurchaseOrder, updateNumberVoucher } from "@/publicjs/vouchers";

//Formatos de impresion
import { printOnA4 } from '@/components/PurchaseOrder/Main/PrintVoucher/PrintOnA4'
import { printOnMediaLetter } from '@/components/PurchaseOrder/Main/PrintVoucher/PrintOnMediaLetter'
import { printOnLetter } from '@/components/PurchaseOrder/Main/PrintVoucher/PrintOnLetter'
import { printTicket } from '@/components/PurchaseOrder/Main/PrintVoucher/PrintTicket'

export default {
  name: "MainPurchaseOrder",
  components: {
    LoadingTables,
    TableHead,
    PurchaseOrderTotalDetails,
  },
  setup() {
    const loading = ref(true);

    const spinner = ref(false); // Sirve para mostrar un loading al momento de enviar información

    //Variables para calcular el total de orden de compra
    const ivaPurchaseOrder = ref(0);
    const subTotalPurchaseOrder = ref(0);
    const totalPurchaseOrder = ref(0);

    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "P. U",
        },
        {
          title: "U.M",
        },
        {
          title: "SubTotal",
        },
        {
          title: config.iva,
        },
        {
          title: "Total",
        },
        {
          title: "acciones",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      idUser: 0,
      idSupplier: null,
      searchSupplier: "",
      waToPay: "",
      placeOfDelivery: "",
      additionalNote: "",
      deadline: null,
      idVoucher: 0,
      numberVoucher: "",
      serieVoucher: "",
    });

    //variable para almacenar detalles de la orden de compra
    const purchaseOrderDetails = ref([]);

    //varibles para los inputs de busqueda
    const showResultsSupplier = ref(false);
    const loadingSearchSupplier = ref(true);
    const resultSearchSupplier = ref([]);
    const resultLengthSupplier = ref(false);
    // esta funcion sirve para buscar usuario por nombre
    const searchSupplier = async () => {
      if (dataForm.value.searchSupplier.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>la razón sozial o el N° de documento del proveedor</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }

      loadingSearchSupplier.value = true;
      showResultsSupplier.value = true;
      resultLengthSupplier.value = false;
      const response = await axios
        .get(
          `proveedores/?page=1&per_page=20&search=${dataForm.value.searchSupplier}`,
          {}
        )
        .catch((error) => errorActions(error));

      if (response.data.data.length > 0) {
        resultLengthSupplier.value = true;
      }
      resultSearchSupplier.value = await response.data.data;
      loadingSearchSupplier.value = false;
    };
    //esta funcion sirve para seleccionar al usuario por su id y se almacena en dataForm.idUser
    const changeSupplierID = async (id, fullName) => {
      dataForm.value.idSupplier = await id;
      dataForm.value.searchSupplier = await fullName;
      showResultsSupplier.value = false;
    };

    //Variables para la busqueda de producto
    const showResultsProducts = ref(false);
    const loadingSearchProducts = ref(true);
    const resultSearchProducts = ref([]);
    const resultLengthProducts = ref(false);
    const searchProduct = ref("");
    // Filtro de productos por estado igual a 1
    const filteredRegisters = ref([]);
    // esta funcion sirve para buscar usuario por nombre
    const searchProducts = async () => {
      if (searchProduct.value.length <= 0) {
        errorActions(
          "Por favor ingresa <strong>el nombre de producto o su codigo de barra</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        return;
      }
      loadingSearchProducts.value = true;
      showResultsProducts.value = true;
      resultLengthProducts.value = false;
      const response = await axios
        .get(
          `productos/?page=1&per_page=50&search=${searchProduct.value}&idstore=${dataForm.value.idStore}`,
          {}
        )
        .catch((error) => errorActions(error));
      filteredRegisters.value = await response.data.data;
      resultSearchProducts.value = filteredRegisters.value.filter(
        (register) => register.estado === 1
      );
      if (resultSearchProducts.value.length > 0) {
        resultLengthProducts.value = true;
      }
      loadingSearchProducts.value = false;
    };

    //Esta funcion se encarga de agregar detalles de la orden de compra a la tabla

    // Esto  permitirá recibir una lista de argumentos y asignarlos a los campos correspondientes en tu objeto orderData
    const addPurchaseOrderDetails = async (...args) => {
      if (args.length <= 4) {
        errorActions("no hay parametros de la funcion");
        return;
      }
      //desestructuramos los parametros que recivimos
      const [
        idProduct,
        taxFree,
        unitMeasure,
        quantity,
        unitPrice,
        product,
        ivaAplied,
      ] = args;
      //parseamos el iva del producto
      const configureIva = parseFloat(ivaAplied) / 100;

      const subTotal = Math.round((unitPrice / (configureIva + 1)) * 100) / 100;
      const iva = Math.round(subTotal * configureIva * 100) / 100;
      const total = quantity * unitPrice;

      // Buscar el producto en la lista existente
      const existingProductIndex = purchaseOrderDetails.value.findIndex(
        (order) => order.idProduct === idProduct
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct =
          purchaseOrderDetails.value[existingProductIndex];
        const newQuantity = existingProduct.quantity + quantity;

        existingProduct.quantity = newQuantity;
        existingProduct.subTotal =
          Math.round(subTotal * newQuantity * 100) / 100;
        existingProduct.iva =
          Math.round(existingProduct.subTotal * configureIva * 100) / 100;
        existingProduct.total = existingProduct.quantity * unitPrice;
      } else {
        //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden de compra
        const orderData = {
          idProduct,
          product,
          taxFree,
          unitMeasure,
          quantity,
          unitPrice,
          subTotal,
          iva,
          total,
          ivaAplied,
        };
        //añadimos el nuevo objeto a la oder de compra
        purchaseOrderDetails.value.push(orderData);
      }

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);

      //cerramos el modal que muestra la lista de producto filtrados o buscados
      showResultsProducts.value = false;
    };

    //funcion para eliminar un registro del arreglo de la orden de compra

    const deletePurchaseOrderDetails = (index) => {
      purchaseOrderDetails.value.splice(index, 1);

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    };

    //funcion para calcular el total de la orden de compra, incluyendo el iva, subtotal y total
    const calculatePurchaseOrderTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      totalPurchaseOrder.value = 0;
      subTotalPurchaseOrder.value = 0;
      ivaPurchaseOrder.value = 0;

      //recorremos todo el detalle de la orden de compra
      order.forEach((element) => {
        totalPurchaseOrder.value += element.total;
        subTotalPurchaseOrder.value += element.subTotal;
        ivaPurchaseOrder.value += element.iva;
      });
    };

    //funcion para cambiar la cantidad de la orden de compra
    const handleQuantityChange = (index, event, ivaAplied) => {
      const newQuantity = parseInt(event.target.value)
        ? parseInt(event.target.value)
        : 1;
      purchaseOrderDetails.value[index].quantity = newQuantity;
      updateOrderDetails(index, ivaAplied);
    };

    //funcion para cambiar el precio de compra de la orden de compra
    const handlePriceChange = (index, event, ivaAplied) => {
      const newPrice = parseFloat(event.target.value)
        ? parseFloat(event.target.value)
        : 1;
      purchaseOrderDetails.value[index].unitPrice = newPrice;
      updateOrderDetails(index, ivaAplied);
    };

    //funciona para actualizar la orden de compra
    const updateOrderDetails = (index, ivaAplied) => {
      const order = purchaseOrderDetails.value[index];
      const totalOrder = order.quantity * order.unitPrice;

      //parseamos el iva del producto
      const configureIva = parseFloat(ivaAplied) / 100;

      //volvemos a calcular el total de la orden de compra del producto seleccionado
      order.subTotal =
        Math.round((totalOrder / (configureIva + 1)) * 100) / 100;
      order.iva = Math.round(order.subTotal * configureIva * 100) / 100;
      order.total = totalOrder;

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    };

    onMounted(async () => {
      //obtenemos el id de la sucursal donde el usuario inicio sesión
      const localStore = await JSON.parse(localStorage.getItem("access"));
      dataForm.value.idStore = localStore.user.store;
      dataForm.value.idUser = localStore.user.key;
    });

    //funcion para insertar una orden de compra
    const insert = async () => {
      //variable donde almacenamos los datos del comprobantes

      spinner.value = true;
      const dataVoucher = await voucherPurchaseOrder();
      dataForm.value.idVoucher = dataVoucher.id;
      dataForm.value.numberVoucher = dataVoucher.numberCurrent;
      dataForm.value.serieVoucher = dataVoucher.serie;
      const printFormat = dataVoucher.printFormat

      if (purchaseOrderDetails.value.length <= 0) {
        errorActions("Por favor agrega productos para realizar la orden de compra");
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );

        spinner.value = false;
        return;
      }
      if (dataForm.value.idVoucher <= 0) {
        errorActions(
          "No se encontro un comprobante llamado <strong>ORDEN DE COMPRA O SIMILAR</strong> para poder emitir este orden, registre uno y vuelve a intentarlo"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );

        spinner.value = false;
        return;
      }
      if (dataForm.value.idSupplier <= 0) {
        errorActions(
          "Por favor selecciona un provedor para emitir <strong>orden de compra</strong>"
        );
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );

        spinner.value = false;
        return;
      }

      try {
        const response = await axios.post(
          "orden-de-compras",
          {
            idStore: dataForm.value.idStore,
            idUser: dataForm.value.idUser,
            idSupplier: dataForm.value.idSupplier,
            idVoucher: dataForm.value.idVoucher,
            numberVoucher: dataForm.value.numberVoucher,
            serieVoucher: dataForm.value.serieVoucher,
            deadline: dataForm.value.deadline,
            waToPay: dataForm.value.waToPay,
            placeOfDelivery: dataForm.value.placeOfDelivery,
            additionalNote: dataForm.value.additionalNote,
            subTotal: subTotalPurchaseOrder.value,
            iva: ivaPurchaseOrder.value,
            total: totalPurchaseOrder.value,
            details:purchaseOrderDetails.value
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        );

        const data = await response.data;
        if (data.msg === true) {

          //Actualizacion del numero de comprobante 
          await updateNumberVoucher(dataForm.value.idVoucher,dataForm.value.numberVoucher)

          //? Impresion de comprobante segun su formato
          if(printFormat === 'formato_a4') {
            await printOnA4(data.id) // Como parametro recive el ID  de la ultima orden de compra
          } else if(printFormat === 'formato_8x11') {
            await printOnLetter(data.id) // Como parametro recive el ID  de la ultima orden de compra
          }else if(printFormat === 'formato_media_carta') {
            await printOnMediaLetter(data.id) // Como parametro recive el ID  de la ultima orden de compra
          }else if(printFormat === 'formato_ticket') {
            await printTicket(data.id) // Como parametro recive el ID  de la ultima orden de compra
          }

          confirmationOfRecord(
            `La orden de compra para el proveedor <strong>${dataForm.value.searchSupplier}</strong>`
          );

          observer.observe(
            document.querySelector(".swal2-container"),
            { box: "content-box" },
            10
          );

          //limpiamos loca campos y la orden de compra
          dataForm.value.deadline = null;
          dataForm.value.waToPay = "";
          dataForm.value.placeOfDelivery = "";
          dataForm.value.additionalNote = "";
          purchaseOrderDetails.value = [];

          //reseteamos el total de la orden compra
          subTotalPurchaseOrder.value = 0;
          ivaPurchaseOrder.value = 0;
          totalPurchaseOrder.value = 0;
        } else if (data.msg === "Request failed with status code 500") {
          errorActions("Request failed with status code 500");
        } else {
          errorActions(
            `Lo sentimos, no pudimos registrar la orden de compra para el proveedor <strong>${dataForm.value.searchSupplier}</strong>`
          );
        }
        spinner.value = false;
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      } catch (error) {
        spinner.value = false;
        errorActions(error);
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
      }
    };


    return {
      loading,
      header,
      totalPurchaseOrder,
      ivaPurchaseOrder,
      subTotalPurchaseOrder,
      money,
      format,
      dataForm,
      changeSupplierID,
      searchSupplier,
      resultLengthSupplier,
      showResultsSupplier,
      resultSearchSupplier,
      loadingSearchSupplier,
      showResultsProducts,
      loadingSearchProducts,
      resultSearchProducts,
      resultLengthProducts,
      searchProduct,
      searchProducts,
      addPurchaseOrderDetails,
      purchaseOrderDetails,
      deletePurchaseOrderDetails,
      handleQuantityChange,
      handlePriceChange,
      insert,
      spinner,
      
      selectRow
    };
  },
};
</script>
