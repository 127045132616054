import Swal from "sweetalert2";
import axios from "@/api";
import { errorActions } from "@/alerts";
//Acciones para eliminar
const deleteData = async (register, id) => {
  const response = await axios
    .delete("accesos-especificos/" + id, {
      headers: {
        "Content-Type": "application/json",
      },
    })
    .catch(function (error) {
      errorActions(error);
    });
  const data = response.data;
  if (data.msg === "Request failed with status code 500") {
    errorActions(data.msg);
  } else if (data.msg === false) {
    errorActions("Lo sentimos no pudimos eliminar el ecceso " + register);
  }
};
//SweetAlert para confirmas la eliminacion del registro
export default (register, id,e,showAccess) => {
  Swal.fire({
    title: "¿Estas seguro que deseas eliminar el acceso del usuario?",
    html: `Si eliminas el acceso  <strong>${register}</strong>, no podras recuperarlo más adelante!`,
    icon: "warning",
    showCancelButton: true,
    confirmButtonColor: "#315df5",
    cancelButtonColor: "#dc2626",
    confirmButtonText: "Si, ¡Eliminar!",
    cancelButtonText: "Cancelar",
    width: "300px",
  }).then(async (result) => {
    if (result.isConfirmed) {
      await deleteData(register, id);
      const element = e.target.closest('label')
      element.children[0].children[0].checked = false
      await showAccess()
      Swal.fire({
        icon: "success",
        title: "Eliminado",
        text: `El acceso ${register}, a sido eliminado de la base de datos`,
        confirmButtonColor: "#315df5",
        confirmButtonText: "Aceptar!",
      });
    }
  });
};
