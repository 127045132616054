<template>
  <div class="mt-4" id="contentReferralGuide">
    <div class="flex justify-between flex-wrap md:flex-nowrap items-end mb-6">
      <div class="flex items-end flex-wrap gap-4">
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>
        <label class="block">
          <span
            class="block mb-2 text-sm font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
            @change="resetValuesAndGetData"
          />
        </label>

        <button
          class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
          @click="resetValuesAndGetData"
        >
          <font-awesome-icon icon="fa-solid fa-search" class="mr-2" />
          Consultar
        </button>
      </div>
      <button
        class="bg-blue-500 text-white py-2 px-3 rounded-lg flex items-center outline-none shadow-sm hover:shadow-blue-500"
        @click="activeModalRegister"
      >
        <font-awesome-icon icon="fa-solid fa-plus" class="mr-2" />
        Nuevo registro
      </button>
    </div>
    <div class="rounded-lg overflow-hidden border">
      <!-- secction para filtar por limite de registro -->
      <div class="py-4 px-3 flex items-center justify-between">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>
        <div class="w-full">
          <label for="modules">
            <input
              type="text"
              class="py-2 px-2 text-sm bg-white border outline-none rounded-lg shadow-sm text-gray-600 w-full"
              placeholder="Buscar por nombre o numero de documento..."
              v-model="search"
              @keydown.enter="resetValuesAndGetData"
            />
          </label>
        </div>
      </div>

      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full" id="dataTable">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="9" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.cliente.nombres }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.serie }} - {{ register.numero_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.punto_de_partida }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.punto_de_llegada }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.transportista }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.telefono_transportista }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.fecha_de_traslado }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.fecha_emision }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 text-right whitespace-nowrap"
                >
                <div class="relative group">
                    <button
                      class="bg-blue-50 flex items-center justify-center w-8 h-8 rounded-md text-blue-500"
                    >
                      <font-awesome-icon icon="fa-solid fa-list-ul" />
                    </button>
                    <ul
                      class="absolute top-full right-0 bg-white rounded-md shadow-xl shadow-blue-300 border-blue-50 border-2 overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
                    >
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherTicket(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 80MM
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnA4(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en A4
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en 8 1/2x11
                      </button>
                      <button
                        class="px-6 py-2 text-gray-500 flex items-center hover:bg-gray-50 whitespace-nowrap w-full"
                        @click="printVoucherOnMediaLetter(register.id)"
                      >
                        <font-awesome-icon
                          icon="fa-solid fa-print"
                          class="mr-2"
                        />
                        Imprimir en M. carta
                      </button>
                    </ul>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>

  <RegisterReferralGuide
    :activeModal="activeModalRegister"
    :getdata="getData"
    v-if="stateRegister"
  />
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import RegisterReferralGuide from "@/components/ReferralGuide/RegisterReferralGuide.vue";
import { errorActions, warningActions } from "@/alerts";

import { formatDate, formatDateTime } from "@/publicjs/formatDate";
import { idLocalStore } from "@/publicjs/localStorage";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import { observeElement } from "@/observer";
import { printVoucherOnA4 } from './PrintVoucher/PrintOnA4'
import { printVoucherOnLetter } from './PrintVoucher/PrintOnLetter'
import { printVoucherOnMediaLetter } from './PrintVoucher/PrintOnMediaLetter'
import { printVoucherTicket } from './PrintVoucher/PrintOnTicket'
import { currentDay } from "@/publicjs/currentDate";
import { selectRow } from "@/publicjs/selectRowTable";


export default {
  name: "MainSalesReferralGuide",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    RegisterReferralGuide,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "clientes",
        },
        {
          title: "n° comprobante",
        },
        {
          title: "punto de partida",
        },
        {
          title: "punto de llegada",
        },
        {
          title: "transportista",
        },
        {
          title: "cel. transportista",
        },
        {
          title: "fec. de traslado",
        },
        {
          title: "fec. emision",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const dataForm = ref({
      idStore: 0,
      dateStart: 0,
      dateEnd: 0,
    });

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const search = ref("");
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      const response = await axios
        .get(
          `guia-de-remision-ventas/?page=${currentPage.value}&per_page=${limitRegister.value}&search=${search.value}&idstore=${dataForm.value.idStore}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}`
        )
        .catch((error) => errorActions(error));
      const data = await response.data.data;
      if (data.length === 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    onMounted(async () => {
      observeElement("#contentReferralGuide");
      observeElement("#dataTable");
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay();
      dataForm.value.dateEnd = dates.lastDay;
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    //activar modal de registro y de actualizacion de datos
    const stateRegister = ref(false);
    const activeModalRegister = () => {
      stateRegister.value = !stateRegister.value;
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      search,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      activeModalRegister,
      stateRegister,
      widthNav,
      handleResize,
      formatDate,
      formatDateTime,
      dataForm,
      printVoucherOnA4,
      printVoucherOnLetter,
      printVoucherOnMediaLetter,
      printVoucherTicket,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
