<template>
  <div class="mt-4" id="contentData">
    <div
      class="flex justify-between flex-wrap gap-4 md:flex-nowrap items-end mb-3"
    >
      <div class="flex flex-wrap items-end gap-4">
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Fec. de inicio
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateStart"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Fec. término
          </span>
          <input
            type="date"
            class="py-2 px-2 text-sm bg-gray-50 outline-none rounded-lg text-gray-600 w-full border-2 focus:border-blue-500 appearance-none"
            v-model="dataForm.dateEnd"
            @keydown.enter.prevent
          />
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Tipo de filtros
          </span>
          <select
            class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500 uppercase"
            v-model="dataForm.typeFilter"
            @change="showComponents"
          >
            <option value="general">Ventas en general</option>
            <option value="seller">Ventas según vendedor</option>
            <option value="customer">Ventas según cliente</option>
          </select>
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Tipo de venta
          </span>
          <select
            class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500 uppercase"
            v-model="dataForm.typeSale"
          >
            <option value="all">todos</option>
            <option value="1">contado</option>
            <option value="2">crédito</option>
          </select>
        </label>
        <label class="block w-full md:w-auto">
          <span
            class="block mb-1 text-xs font-medium text-gray-800 dark:text-white"
          >
            Tipo de pago
          </span>
          <select
            class="border-2 bg-gray-50 py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg mr-3 outline-none min-w-[140px] font-semibold w-full focus:border-blue-500 uppercase"
            v-model="dataForm.typePayment"
          >
            <option value="all">todos</option>
            <option value="1">efectivo</option>
            <option value="2">tarjeta</option>
            <option value="3">transferencia</option>
            <option value="4">Cheque</option>
            <option value="5">p. combinado</option>
          </select>
        </label>
        <button
          class="bg-blue-500 w-full md:w-auto text-white hover:bg-blue-600 border-2 border-blue-500 py-2 rounded-md active:scale-95 px-2 text-sm"
          @click="resetValuesAndGetData"
        >
          <font-awesome-icon icon="fa-solid fa-search" class="rotate-90 mr-1" />
          Generar reporte
        </button>
      </div>
    </div>
    <div
      v-if="stateCustomer"
      class="bg-blue-50 px-3 py-3 rounded-md border-2 border-blue-100 mb-3"
    >
      <SearchCustomer @send-data="getDataCustomer" />
    </div>
    <div
      v-if="stateSeller"
      class="bg-blue-50 px-3 py-3 rounded-md border-2 border-blue-100 mb-3"
    >
      <SearchSeller @send-data="getDataSeller" />
    </div>
    <div class="rounded-lg overflow-hidden border">
      <div class="flex flex-wrap justify-between gap-4 w-full px-3 py-3">
        <select
          v-model="limitRegister"
          class="bg-white border py-2 text-sm px-3 text-gray-600 cursor-pointer rounded-lg outline-none min-w-[140px] font-semibold w-full md:w-auto"
          @change="resetValuesAndGetData"
        >
          <option value="10">10 Registros</option>
          <option value="25">25 Registros</option>
          <option value="50">50 Registros</option>
          <option value="100">100 Registros</option>
          <option value="500">500 Registros</option>
          <option value="1000">1000 Registros</option>
        </select>

        <!-- acciones de impresion -->
        <div class="relative group">
          <button
            class="bg-blue-100 flex items-center justify-center w-28 h-10 rounded-md text-blue-600"
          >
            Opciones
            <font-awesome-icon
              icon="fa-solid fa-ellipsis"
              class="rotate-90 ml-2"
            />
          </button>
          <ul
            class="absolute top-full right-0 bg-white rounded-md shadow-lg overflow-hidden transition-all duration-300 transform scale-0 group-hover:scale-100 z-50"
          >
            <button
              class="px-6 py-2 text-orange-500 flex items-center hover:bg-orange-50 whitespace-nowrap w-full"
              @click="exportToPDF(registers, dataForm.title,dataForm.dateEnd,dataForm.dateStart)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a PDF
            </button>
            <button
              class="px-6 py-2 text-green-500 flex items-center hover:bg-green-50 whitespace-nowrap w-full"
              @click="exportToExcel(registers, dataForm.title,dataForm.dateEnd,dataForm.dateStart)"
            >
              <font-awesome-icon icon="fa-solid fa-print" class="mr-2" />
              Exportar a excel
            </button>
          </ul>
        </div>
      </div>
      <!-- seccion de conteido de datos tablas
                    1: Para poner una tabla responsiva añadir lo siguiente:
                      1.1: class="resize-generate"
                      1.2: ref="widthNav" 
                      1.3: @resize="handleResize"
                    -->
      <div
        class="w-full resize-x resize-generate"
        ref="widthNav"
        @resize="handleResize"
      >
        <div class="overflow-x-auto">
          <table class="table-auto w-full">
            <TableHead :headers="header" />
            <LoadingTables v-if="loading" :columns="8" />
            <tbody class="text-gray-500 text-left border-t-[1px]" v-else>
              <tr
                v-for="(register, i) in registers"
                :key="i"
                class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
              >
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.nombre_cliente_pedido }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.descripcion }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap uppercase"
                >
                  {{ register.serie_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ register.numero_comprobante }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.igv) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ money() }} {{ format(register.total) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                >
                  {{ formatDateTime(register.fecha_de_emision) }}
                </td>
                <td
                  class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                >
                  <span
                    class="px-2 py-1 rounded-md"
                    :class="{
                      'bg-green-50 text-green-600':
                        register.estado === 'EFECTUADA',
                      'bg-red-50 text-red-500':
                        register.estado === 'MODIFICADO',
                      'bg-blue-50 text-blue-500':
                        register.estado === 'VENTA A CREDITO',
                    }"
                  >
                    {{ register.estado }}
                  </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <TablePagination
        :currentPage="currentPage"
        :total="totalPage"
        :next="next"
        :prev="prev"
      />
    </div>
  </div>
</template>

<script>
import { onMounted, ref, watchEffect } from "vue";
import axios from "@/api";
import LoadingTables from "@/components/Loadings/LoadingTables";
import TableHead from "@/components/TablesComponents/TableHead";
import TablePagination from "@/components/TablesComponents/TablePagination.vue";
import { errorActions, warningActions } from "@/alerts";
import { observeElement } from "@/observer";

import { money, format } from "@/publicjs/money";
import { idLocalStore } from "@/publicjs/localStorage";
import config from "/public/apis/config.json";

import { formatDateTime } from "@/publicjs/formatDate";
import { exportToPDF } from "./Reports/PDFExportSales";
import { exportToExcel } from "./Reports/ExcelExportAllSales";
import { firstDaytAndLastDay } from "@/publicjs/getFirstAndLastDayOfMount";
import SearchCustomer from "./SearchCustomer.vue";
import SearchSeller from "./SearchSeller.vue";
import { currentDay } from "@/publicjs/currentDate";
import { selectRow } from "@/publicjs/selectRowTable";

export default {
  name: "MainReportSales",
  components: {
    LoadingTables,
    TableHead,
    TablePagination,
    SearchSeller,
    SearchCustomer,
  },
  setup() {
    // carga de datos a la tabla
    const registers = ref([]);
    const paginate = ["registers"];
    const loading = ref(true);
    const header = [
      [
        {
          title: "Cliente",
        },
        {
          title: "Comprobante",
        },
        {
          title: "Serie",
        },
        {
          title: "N° de venta",
        },
        {
          title: config.iva,
        },
        {
          title: "total",
        },
        {
          title: "fec. emisión",
        },
        {
          title: "estado",
        },
      ],
    ];
    const dataForm = ref({
      idStore: 0,
      typeFilter: "general",
      typeSale: "all",
      typePayment: "all",
      dateStart: null,
      dateEnd: null,
      title: "REPORTE GENERAL DE VENTAS",
      idCustomer: 0,
      nameCustomer: "",
      idSeller: 0,
      nameSeller: "",
    });

    const stateCustomer = ref(false);
    const stateSeller = ref(false);

    //variables de paginacion
    const currentPage = ref(1);
    const limitRegister = ref(10);
    const resgisterPaginate = ref(null);
    const totalPage = ref(0);
    const getData = async () => {
      loading.value = true;
      if (
        dataForm.value.dateEnd === null ||
        dataForm.value.dateEnd === "" ||
        dataForm.value.dateStart === null ||
        dataForm.value.dateStart === ""
      ) {
        errorActions(
          "Por favor selecciona el rango de fechas para poder realizar el reporte de ventas."
        );
        loading.value = false;
        return;
      }
      const response = await axios
        .get(
          `reporte-de-ventas/${dataForm.value.idStore}/?page=${currentPage.value}&per_page=${limitRegister.value}&type_payment=${dataForm.value.typePayment}&type_sale=${dataForm.value.typeSale}&date_start=${dataForm.value.dateStart}&date_end=${dataForm.value.dateEnd}&idseller=${dataForm.value.idSeller}&idcustomer=${dataForm.value.idCustomer}&type_filter=${dataForm.value.typeFilter}`
        )
        .catch((error) => errorActions(error));
      // validacion para mostrar una alerta cuando no se ha encontrado un registro
      if ((await response.data.data.length) <= 0) {
        warningActions(
          "Los sentimos no pudimos encontrar lo que estabas buscando 😥"
        );
      }
      registers.value = await response.data.data;
      resgisterPaginate.value = await response.data;
      totalPage.value = resgisterPaginate.value.last_page;
      loading.value = false;
    };

    //funcion para recetear valores
    const resetValuesAndGetData = async () => {
      currentPage.value = 1;
      await getData();
    };

    //calcular el ancho del nav y hacer el scroll en x de la tabla
    const widthNav = ref(null);
    const handleResize = () => {
      const nav = document.querySelector("nav").offsetWidth;
      watchEffect(
        () => {
          if (widthNav.value) {
            widthNav.value.style.width = `calc(100vw - ${nav + 42}px)`;
          }
        }
        // { immediate: true }
      );
    };

    //Pasar el día actual y el ultimo día de la fecha
    const changeCurrentDate = async () => {
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay();
      dataForm.value.dateEnd = dates.lastDay;
      await resetValuesAndGetData();
    };
    onMounted(async () => {
      observeElement("#contentData");
      handleResize();
      window.addEventListener("resize", handleResize);
      dataForm.value.idStore = await idLocalStore();
      
      const dates = await firstDaytAndLastDay();
      dataForm.value.dateStart = currentDay()
      dataForm.value.dateEnd = dates.lastDay
      await changeCurrentDate();
      await getData();
    });

    //! Funciones para avanzar y retrocedeer en la paginaciones
    const next = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value <= totalPage.value - 1) currentPage.value++;
      await getData();
    };
    const prev = async () => {
      totalPage.value = resgisterPaginate.value.last_page;
      if (currentPage.value >= 2) currentPage.value--;
      await getData();
    };

    const getDataCustomer = async (data) => {
      dataForm.value.idCustomer = data.id;
      dataForm.value.nameCustomer = data.fullName;
      dataForm.value.title = `REPORTE POR CLIENTE: ${data.fullName}`;
    };
    const getDataSeller = async (data) => {
      dataForm.value.idSeller = data.id;
      dataForm.value.nameSeller = data.fullName;
      dataForm.value.title = `REPORTE POR VENDEDOR: ${data.fullName}`;
    };

    const showComponents = async () => {
      if (dataForm.value.typeFilter === "customer") {
        stateCustomer.value = true;
        stateSeller.value = false;
      } else if (dataForm.value.typeFilter === "seller") {
        stateCustomer.value = false;
        stateSeller.value = true;
      } else {
        stateCustomer.value = false;
        stateSeller.value = false;
        dataForm.value.title = `REPORTE DE VENTAS ${dataForm.value.typeSale ===1?'AL CONTADO':dataForm.value.typeSale ===1?'AL CREDITO':'GENERAL'}`;
      }
    };

    return {
      loading,
      getData,
      paginate,
      header,
      registers,
      limitRegister,
      currentPage,
      next,
      prev,
      totalPage,
      resetValuesAndGetData,
      widthNav,
      handleResize,
      money,
      format,
      dataForm,
      formatDateTime,
      exportToPDF,
      exportToExcel,
      stateSeller,
      stateCustomer,
      getDataSeller,
      getDataCustomer,
      showComponents,
      selectRow
    };
  },
};
</script>

<style scoped>
.active button svg {
  rotate: 90deg;
}
</style>
./Reports/ExcelExportAllSales./Reports/PDFExportSales
