<template>
  <div
    class="fixed z-50 inset-0 overflow-y-auto"
    id="main-content-credit-note-sale"
  >
    <div class="flex items-center py-8 justify-center min-h-screen">
      <div class="fixed inset-0 transition-opacity">
        <div class="absolute inset-0 bg-black opacity-50"></div>
      </div>
      <div
        class="bg-white rounded-lg overflow-hidden shadow-xl px-6 py-6 z-50 max-w-[90vw] min-w-[90vw] md:min-w-[80vw] lg:min-w-[70vw] md:max-w-[80vw] lg:max-w-[80vw]"
        id="subcontent-credit-note-sale"
      >
        <div class="flex justify-between items-center mb-6">
          <h2
            class="text-xl font-medium text-gray-800 dark:text-white uppercase"
          >
            Modificando orden de compra
          </h2>
          <button
            @click="showModal"
            class="text-gray-500 hover:text-gray-700 w-8 h-8 flex items-center justify-center hover:bg-gray-200 rounded-lg"
          >
            <svg class="w-5 h-5">
              <use href="@/assets/svg/icon.svg#cancel" />
            </svg>
          </button>
        </div>
        <div class="w-full">
          <div
            class="py-3 bg-blue-50 px-3 rounded-md grid md:grid-cols-12 gap-4"
          >
            <SearchProduct
              @send-data="addPurchaseOrderDetails"
              :isShopping="true"
              :typeVoucher="'NINGUNA'"
            />
          </div>
          <div>
            <div
              class="w-full resize-x resize-generate"
              ref="widthNav"
              @resize="handleResize"
            >
              <div class="overflow-x-auto">
                <table class="table-auto w-full">
                  <TableHead :headers="header" />
                  <tbody class="text-gray-500 text-left border-t-[1px]">
                    <tr
                      v-for="(sale, i) in purchaseOrderDetails"
                      :key="i"
                      class="hover:bg-blue-500 transition-colors hover:text-white capitalize font-semibold odd:bg-white even:bg-slate-50"
                @click="selectRow($event)" 
                    >
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.product }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        <input
                          type="number"
                          min="1"
                          :value="sale.quantity"
                          class="bg-white text-gray-500 outline-none py-1 px-1 max-w-[45px] rounded-md border-2 border-blue-100 text-center"
                          @blur="handlePriceChange(i, $event, sale.igvApplied)"
                          @keyup.enter="
                            handlePriceChange(i, $event, sale.igvApplied)
                          "
                        />
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ sale.unitMeasure }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.unitPrice) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap"
                      >
                        {{ money() }} {{ format(sale.total) }}
                      </td>
                      <td
                        class="border-slate-200 text-sm px-6 py-2 whitespace-nowrap text-right"
                      >
                        <div class="flex justify-end">
                          <button
                            class="bg-red-100 text-red-500 hover:text-white p-2 w-8 h-8 text-sm active:scale-105 hover:bg-red-500 flex items-center justify-center rounded-md"
                            @click="removeProduct(i, sale.id)"
                            v-if="!sale.isNew"
                          >
                            <font-awesome-icon
                              icon="fa-solid fa-trash"
                              class="w-3 h-3"
                            />
                          </button>
                          <button
                            class="bg-red-100 text-red-500 hover:text-white p-2 w-8 h-8 text-sm active:scale-105 hover:bg-red-500 flex items-center justify-center rounded-md"
                            @click="removeProduct(i, 0)"
                            v-if="sale.isNew"
                          >
                            <font-awesome-icon
                              icon="fa-solid fa-trash"
                              class="w-3 h-3"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div class="p-4 bg-blue-50 text-blue-500 flex flex-col items-end">
              <div>
                <strong>ITBIS:</strong> {{ money() }} {{ format(dataForm.igv) }}
              </div>
              <div>
                <strong>SUBTOTAL:</strong> {{ money() }}
                {{ format(dataForm.subTotal) }}
              </div>
              <div>
                <strong>TOTAL:</strong> {{ money() }}
                {{ format(dataForm.total) }}
              </div>
            </div>

            <div class="flex mt-6 justify-end">
              <button
                class="bg-blue-500 text-white px-4 py-2 font-semibold text-sm rounded-lg hover:shadow-sm hover:shadow-blue-600 active:scale-95 flex items-center"
                @click="update"
                :disabled="spinner === true"
              >
                <div class="flex items-center" v-if="spinner">
                  <svg class="w-4 h-4 mr-3 animate-spin">
                    <use href="@/assets/svg/icon.svg#spinnerForBlue" />
                  </svg>
                  Modificando orden
                </div>
                <span v-else>Modificar orden</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { confirmationOfUpdate, errorActions, successAction } from "@/alerts";
import { observeElement, observer } from "@/observer";
import TableHead from "@/components/TablesComponents/TableHead";
import axios from "@/api";
import { selectRow } from "@/publicjs/selectRowTable";
import { format, money } from "@/publicjs/money";
import Swal from "sweetalert2";
import SearchProduct from "@/components/Sales/Searchs/SearchProduct.vue";
export default {
  name: "UpdateOrder",
  props: {
    showModal: {
      type: Function,
      required: true,
    },
    getdata: {
      type: Function,
      required: true,
    },
    id: {
      type: Number,
      required: true,
    },
    details: {
      type: Object,
      required: true,
    },
  },
  components: {
    TableHead,
    SearchProduct,
  },
  setup(props) {
    const spinner = ref(false);

    const dataForm = ref({
      total: 0,
      subTotal: 0,
      igv: 0,
    });

    const header = [
      [
        {
          title: "Descripción",
        },
        {
          title: "Cantidad",
        },
        {
          title: "U.M",
        },
        {
          title: "PU",
        },
        {
          title: "Total",
        },
        {
          title: "Acciones",
        },
      ],
    ];

    const purchaseOrderDetails = ref([]);

    /*****************************************************************************
     * Funcion para añadir producto a lista de productos
     * @param {*} detail detalle de la venta
     *****************************************************************************/
    const addPurchaseOrderDetails = async (data) => {
      //desestructuramos los parametros que recivimos
      const {
        idProduct,
        taxFree,
        unitMeasure,
        quantity,
        shoppingPrice,
        productName,
        igvApplied,
      } = data;
      //parseamos el iva del producto
      const configureIva = parseFloat(igvApplied) / 100;

      const subTotal =
        Math.round((shoppingPrice / (configureIva + 1)) * 100) / 100;
      const iva = Math.round(subTotal * configureIva * 100) / 100;
      const total = quantity * shoppingPrice;

      // Buscar el producto en la lista existente
      const existingProductIndex = purchaseOrderDetails.value.findIndex(
        (order) => order.idProduct === idProduct
      );

      if (existingProductIndex !== -1) {
        // El producto ya existe en la lista, sumar la cantidad y actualizar los precios
        const existingProduct =
          purchaseOrderDetails.value[existingProductIndex];
        const newQuantity = existingProduct.quantity + quantity;

        existingProduct.quantity = newQuantity;
        existingProduct.subTotal =
          Math.round(subTotal * newQuantity * 100) / 100;
        existingProduct.iva =
          Math.round(existingProduct.subTotal * configureIva * 100) / 100;
        existingProduct.total = existingProduct.quantity * shoppingPrice;
      } else {
        //Si el producto no existe agregamos un nuevo producto a la lista de arreglos de la orden de compra
        const orderData = {
          id:0,
          idProduct,
          product: productName,
          taxFree,
          unitMeasure,
          quantity,
          unitPrice: parseFloat(shoppingPrice),
          subTotal,
          iva,
          total,
          igvApplied: parseFloat(igvApplied),
          isNew:true
        };
        //añadimos el nuevo objeto a la oder de compra
        purchaseOrderDetails.value.push(orderData);
      }

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    };

    /*****************************************************************************
     * Funcion para quitar productos de la lista de devolucion
     * @param {*} index Indice del registro que sea elminado
     * @param {*} idSale ID de la venta para saber si los productos son productos ya vendidos o productos agregado recientemente
     * @param {*} idDetail ID del detalle de la venta
     *****************************************************************************/
    const removeProduct = async (index, idSale) => {
      Swal.fire({
        title: "¿Estas seguro que deseas eliminar el producto?",
        text: `Si eliminas el producto de la venta, ya no podrás recuperarlo más adelante!`,
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#315df5",
        cancelButtonColor: "#dc2626",
        confirmButtonText: "Si, ¡eliminar!",
        cancelButtonText: "Cancelar",
        width: "300px",
      }).then(async (result) => {
        if (result.isConfirmed) {
          // Verificar si el índice está dentro de los límites del array
          if (index >= 0 && index < purchaseOrderDetails.value.length) {
            if (idSale !== 0) {
              //Elminamos el productos desde la base de datos
              await deleteProduct(idSale);
            } else {
              await successAction("Eliminado", "Producto quitado con éxito");
            }

            purchaseOrderDetails.value.splice(index, 1);
            calculatePurchaseOrderTotal(purchaseOrderDetails.value);
          } else {
            errorActions("Índice inválido. No se pudo remover el producto.");
          }
        }
      });
    };
    /*****************************************************************************
     * Funcion para eliminar el producto a nivel de base de datos
     * @param {*} id Indice del registro que sera eliminado
     *****************************************************************************/
    const deleteProduct = async (id) => {
      const response = await axios
        .delete(`modificar-orden-de-compras/${id}`)
        .catch((error) => errorActions(error));
      const data = await response.data;
      if (data.msg === true) {
        await successAction(
          "Elminado",
          "producto de orden de compra eliminado con éxito de la base de datos."
        );
      } else {
        errorActions("Lo sentimos no pudimos eliminar el detalle de la venta");
      }
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    };

    //funcion para calcular el total de la orden de compra, incluyendo el iva, subtotal y total
    const calculatePurchaseOrderTotal = (order) => {
      //nos aseguramos que la variables se inicialicen en cero
      dataForm.value.total = 0;
      dataForm.value.subTotal = 0;
      dataForm.value.igv = 0;

      //recorremos todo el detalle de la orden de compra
      order.forEach((element) => {
        dataForm.value.total += parseFloat(element.total);
        dataForm.value.subTotal += parseFloat(element.subTotal);
        dataForm.value.igv += parseFloat(element.iva);
      });
    };

    //funcion para cambiar la cantidad de la orden de compra
    const handlePriceChange = (index, event, igvApplied) => {
      const newQuantity = parseInt(event.target.value)
        ? parseInt(event.target.value)
        : 1;
      purchaseOrderDetails.value[index].quantity = newQuantity;
      updateOrderDetails(index, igvApplied);
    };

    //funciona para actualizar la orden de compra
    const updateOrderDetails = (index, igvApplied) => {
      const order = purchaseOrderDetails.value[index];
      const totalOrder = order.quantity * order.unitPrice;
      
      //parseamos el iva del producto
      const configureIva = parseFloat(igvApplied) / 100;

      //volvemos a calcular el total de la orden de compra del producto seleccionado
      order.subTotal =
        Math.round((totalOrder / (configureIva + 1)) * 100) / 100;
      order.iva = Math.round(order.subTotal * configureIva * 100) / 100;
      order.total = totalOrder;

      //llamamos la funcion para calcular la orden de compra y le pasamos como parametro el detalle de la orden
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    };

    //Funcion para modificar la orden del cliente
    const update = async () => {
      spinner.value = true;

      const response = await axios
        .put(
          "modificar-orden-de-compras/" + props.id,
          {
            total: dataForm.value.total,
            subTotal: dataForm.value.subTotal,
            igv: dataForm.value.igv,
            details: purchaseOrderDetails.value,
          },
          {
            headers: {
              "Content-Type": "application/json",
            },
          }
        )
        .catch(function (error) {
          errorActions(error);
        });
      const data = await response.data;
      if (data.msg === true) {
        confirmationOfUpdate();
        observer.observe(
          document.querySelector(".swal2-container"),
          { box: "content-box" },
          10
        );
        await props.showModal();
        await props.getdata();
      } else if (data.msg === "Request failed with status code 500") {
        errorActions("Request failed with status code 500");
      } else if (data.status === 404) {
        errorActions(
          `La orden que estas intentando actualizar no existe en nuestra base de datos`
        );
      } else {
        errorActions(
          `Lo sentimos no pudimos actualizar los datos de la <trong> ORDEN </strong>`
        );
      }

      spinner.value = false;
      observer.observe(
        document.querySelector(".swal2-container"),
        { box: "content-box" },
        10
      );
    };

    onMounted(async () => {
      observeElement("#main-content-credit-note-sale");
      observeElement("#subcontent-credit-note-sale");
      purchaseOrderDetails.value = props.details.map((item) => {
        return {
          id:item.id,
          subTotal: parseFloat(item.subtotal),
          iva: parseFloat(item.igv),
          total:parseFloat( item.total),
          idProduct: item.idproducto,
          taxFree: item.libre_de_impuesto,
          unitMeasure: item.unidad_de_medida,
          quantity: parseFloat(item.cantidad),
          unitPrice: parseFloat(item.precio_unitario),
          product: item.nombre_producto,
          igvApplied: parseInt(item.igv_aplicado),
          isNew:false
        };
      });
      calculatePurchaseOrderTotal(purchaseOrderDetails.value);
    });

    return {
      dataForm,
      spinner,
      header,
      purchaseOrderDetails,
      format,
      money,
      addPurchaseOrderDetails,
      removeProduct,
      handlePriceChange,
      update,
      selectRow
    };
  },
};
</script>
